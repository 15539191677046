import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import type { ETypeEntry } from 'typedefs/EType';
import { EType } from 'typedefs/EType';
import { StringUtils } from './StringUtils';

/** Represents a uniform path which consists of a project and a path within that project. This class is immutable. */
export class UniformPath {
	/** Also referenced from soy templates the path within the project. */
	private readonly path: string;

	/** The type of this uniform path (e.g. 'CODE' or 'ARCHITECTURE') */
	public readonly type: ETypeEntry;

	/** @param uniformPath The path, separated by at least one slash. */
	public constructor(uniformPath: string) {
		uniformPath = UniformPath.normalizePath(uniformPath);
		this.path = uniformPath;
		this.type = UniformPath.getUniformPathType(this);
	}

	private static getUniformPathType(path: UniformPath): ETypeEntry {
		for (const pathType of EType.values) {
			if (path.isOfType(pathType)) {
				return pathType.name;
			}
		}
		// Happens if we e.g. have an empty path, where 'CODE' is the default behavior
		return EType.CODE.name;
	}

	/** Removes leading slashes and dots as well as repeated slashed. */
	public static normalizePath(uniformPath: string): string {
		// Remove leading dots and slashes
		uniformPath = uniformPath.replace(/^[./]*/, '');

		// Remove duplicated non-escaped slashes
		uniformPath = uniformPath.replace(/([^\\]\/)\/+/, '$1');
		return uniformPath;
	}

	/** @returns <code>true</code> iff this uniform path is empty, i.e. has no path component. */
	public isEmpty(): boolean {
		return StringUtils.isEmptyOrWhitespace(this.path);
	}

	/** @returns The path within the project. If the project is the empty string, this will also be the empty string. */
	public getPath(): string {
		return this.path;
	}

	/**
	 * @returns A new uniform path to the parent container of this path. Stays within the same project, i.e. if this
	 *   path is the root of a project, it returns a copy of this path.
	 */
	public getParentPath(): UniformPath {
		asserts.assert(!this.isProjectRoot(), 'Cannot get parent of project root path!');
		const parentPath = UniformPath.splitIntoPathAndName(this.path)[0]!;
		return new UniformPath(parentPath);
	}

	/**
	 * @returns The basename of the current path (without trailing slash). If the path is empty, returns the empty
	 *   string.
	 */
	public getBasename(): string {
		if (this.isEmpty()) {
			return '';
		}
		return UniformPath.splitIntoPathAndName(this.path)[1]!;
	}

	/**
	 * Splits the path component into dirname and basename. The dirname contains a trailing slash as leaving the slash
	 * out leads to issues when performing prefix queries as files starting with the same name as the folder would also
	 * be picked up.
	 *
	 * Adapted from the closure library since strings.path.getDirname and getBasename are not currently included in our
	 * version of it.
	 *
	 * @returns An array containing the dirname at position 0 and the basename at position 1.
	 */
	public static splitIntoPathAndName(path: string): [string, string] {
		path = StringUtils.stripSuffix(path, '/');
		let lastSlash = StringUtils.lastIndexOfUnescapedSlash(path);
		if (lastSlash === path.length - 1) {
			lastSlash = StringUtils.lastIndexOfUnescapedSlash(path.substring(0, path.length - 1));
		}
		const baseName = StringUtils.unEscape(path.slice(lastSlash + 1));
		const dirName = path.slice(0, lastSlash + 1);
		return [dirName, baseName];
	}

	/**
	 * Splits the path component into dirname and basename. The dirname contains a trailing slash as leaving the slash
	 * out leads to issues when performing prefix queries as files starting with the same name as the folder would also
	 * be picked up.
	 *
	 * Adapted from the closure library since strings.path.getDirname and getBasename are not currently included in our
	 * version of it.
	 *
	 * @returns An array containing the dirname at position 0 and the basename at position 1.
	 */
	public static splitIntoSegments(path: string): string[] {
		let remainder = path;
		const result: string[] = [];
		do {
			const [dir, name] = UniformPath.splitIntoPathAndName(remainder);
			result.unshift(name);
			remainder = dir;
		} while (remainder !== '');
		return result;
	}

	/** @returns <code>true</code> iff this path is the root folder of a project. */
	public isProjectRoot(): boolean {
		return StringUtils.isEmptyOrWhitespace(this.path);
	}

	/** @returns Whether the uniform path has a storage prefix of the given type. */
	public isOfType(type: EType) {
		return this.path.startsWith(type.prefix + '/') || this.path === type.prefix;
	}

	/** @returns A string representation of the uniform path. */
	public toString(): string {
		return '/' + this.path;
	}

	/**
	 * Returns an array that contains the path parts, e.g. ['src', 'foo', 'Bar.java']. Removes the project and potential
	 * storage prefixes (e.g. '-architecture-') from the result.
	 */
	public getSegmentsWithoutStoragePrefix(): string[] {
		if (this.isEmpty()) {
			return [''];
		}

		const segments = this.getSegments();
		const prefixes = EType.values.map(type => type.prefix);
		if (segments.length > 0 && prefixes.includes(segments[0]!)) {
			return segments.slice(1);
		}
		return segments;
	}

	public getSegments(): string[] {
		return UniformPath.splitIntoSegments(this.path);
	}
}
