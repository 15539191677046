import { UIUtils } from './UIUtils';

/**
 * An url object mapper using a key schema to map an object an array of encoded strings corresponding to the keys of the
 * object provided in the schema.
 *
 * An example key schema would be: ['revision', 'timestamp'] It can be used to encode and decode the values of an object
 * like this: { revision: 'foo', timestamp: 3 }
 *
 * Mapper.encode({ revision: 'foo bar', timestamp: 3 }) = [ '%22foo%20bar%22', '3' ]; mapper.decode([ '%22foo%20bar%22',
 * '3' ]) = { revision: 'foo bar', timestamp: 3 };
 *
 * Note that types of primitive values are preserved.
 */
export class URLObjectMapper<T> {
	public constructor(private readonly keySchema: string[]) {
		// Empty constructor
	}

	/**
	 * Decodes each property back to it's original properly typed value. The key schema value order is used to map the
	 * properties back to the respective fields in the object. Returns null if the key schema doesn't have the same
	 * number of entries as the number of serialized values.
	 */
	public decode(serializedValues: string[]): T | null {
		if (this.keySchema.length !== serializedValues.length) {
			return null;
		}
		const result: Record<string, unknown> = {};
		serializedValues.forEach((serializedValue, index) => {
			result[this.keySchema[index]!] = UIUtils.parseJsonRobust(decodeURIComponent(serializedValue));
		});
		return result as T;
	}

	/**
	 * Encodes the given properties to url encoded strings. The key schema order is used to determine the value order in
	 * the resulting array of encoded values.
	 */
	public encode(keyValueObject: T): string[] {
		return this.keySchema.map(key =>
			encodeURIComponent(JSON.stringify((keyValueObject as Record<string, unknown>)[key]!))
		);
	}
}
