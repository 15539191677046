/** Generated from EFindingEnablement.java. Do not modify this file! */


export type EFindingEnablementEntry = 'OFF' | 'YELLOW' | 'RED' | 'AUTO';

export class EFindingEnablement {
	static readonly OFF = new EFindingEnablement(0, 'OFF');
	static readonly YELLOW = new EFindingEnablement(1, 'YELLOW');
	static readonly RED = new EFindingEnablement(2, 'RED');
	static readonly AUTO = new EFindingEnablement(3, 'AUTO');
	static readonly values = [
		EFindingEnablement.OFF,
		EFindingEnablement.YELLOW,
		EFindingEnablement.RED,
		EFindingEnablement.AUTO
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EFindingEnablementEntry,

	){
	}

	toString() {
		return this.name;
	}
}

