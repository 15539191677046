import type { Blocker, History, Listener, Location, PartialPath, State, To, Transition, Update } from 'history';
import { Action, createPath, parsePath } from 'history';
import { StringUtils } from 'ts/commons/StringUtils';

/** Generates a random key. */
function createKey() {
	return Math.random().toString(36).substring(2, 10);
}

/** The history state. */
type HistoryState = {
	usr: State;
	key?: string;
	idx: number;
};

/** Type for the event handler. */
type Events<A, F extends (arg: A) => void> = {
	length: number;
	push: (fn: F) => () => void;
	call: (arg: A) => void;
};

/**
 * Creates an event handler collection to which event handlers can be added, removed and events can be broadcasted to
 * all currently registered event handlers.
 */
function createEvents<A, F extends (arg: A) => void>(): Events<A, F> {
	let handlers: F[] = [];

	return {
		get length() {
			return handlers.length;
		},
		push(fn: F) {
			handlers.push(fn);
			return function () {
				handlers = handlers.filter(handler => handler !== fn);
			};
		},
		call(arg: A) {
			handlers.forEach(fn => fn(arg));
		}
	};
}

/** Extracts the client side url prefix from the current browser location. */
export function getBaseHref(): string {
	const urlPrefix = new URL('.', window.location.href).pathname;
	return StringUtils.stripSuffix(urlPrefix, '/');
}

/**
 * Converts a complete path (including the URL prefix as used in the browser) to the artificial path as used in React
 * Router.
 */
export function absoluteLocationToArtificialPath(browserPath: PartialPath): PartialPath {
	let { pathname = '', hash = '' } = browserPath;
	pathname = StringUtils.stripPrefix(pathname, getBaseHref());
	return relativeLocationToArtificialPath({ pathname, hash });
}

/** Converts a path without a URL prefix from the browser format into an artificial path as used in React Router. */
function relativeLocationToArtificialPath(browserPath: PartialPath): PartialPath {
	let { pathname = '', hash } = browserPath;
	pathname = StringUtils.stripSuffix(pathname, '/');
	pathname = StringUtils.stripSuffix(pathname, '.html');
	pathname = pathname + '/';
	if (!hash) {
		return {
			pathname,
			search: '',
			hash: ''
		};
	}
	const hashPath = parsePath(hash.substring(1));
	const hashPathname = hashPath.pathname;
	if (hashPathname) {
		pathname = pathname + hashPathname;
	}
	return {
		pathname,
		search: hashPath.search,
		hash: hashPath.hash
	};
}

/**
 * Converts an artificial path as used internally to a URL as seen in the browser. E.g. /findings/detail?a=b becomes
 * /findings.html#detail?a=b
 */
export function artificialPathToUrl(artificialPath: string): string {
	let slashIndex = artificialPath.indexOf('/', 1);
	if (slashIndex === -1) {
		slashIndex = artificialPath.length;
	}
	const perspectiveHtmlFragment = artificialPath.substring(0, slashIndex) + '.html';
	const navigationHash = artificialPath.substring(slashIndex + 1);
	if (navigationHash) {
		return perspectiveHtmlFragment + '#' + navigationHash;
	} else {
		return perspectiveHtmlFragment;
	}
}

/** Converts from To to PartialPath. */
function toPartialPath(to: To): PartialPath {
	if (typeof to === 'string') {
		return parsePath(to);
	} else {
		return to;
	}
}

/**
 * Perspective hash history stores the location in regular URLs and the URLs hash. This history is used to deal with
 * Teamscale's perspective based URLs. The part that describes the perspective is encoded as the last segment of the
 * URL. The position within the perspective is stored as a hash value. Internally the history behaves just like a normal
 * BrowserHistory.
 *
 * Most of the code below is copy of
 * https://github.com/ReactTraining/history/blob/28c89f4091ae9e1b0001341ea60c629674e83627/packages/history/index.ts#L616
 */
export function createPerspectiveHashHistory(window: Window): History {
	const globalHistory = window.history;

	function getIndexAndLocation(): [number | null, Location] {
		const state = globalHistory.state || {};
		const artificialPath = absoluteLocationToArtificialPath(window.location);
		return [
			state.idx,
			{
				pathname: artificialPath.pathname ?? '',
				search: artificialPath.search ?? '',
				hash: artificialPath.hash ?? '',
				state: state.usr || null,
				key: state.key || 'default'
			}
		];
	}

	let blockedPopTx: Transition | null = null;

	/**
	 * Handles a pop navigation event, which means either a back navigation has happened or the URL has been changed by
	 * other external means i.e., bookmark navigation or manually changing the browser URL.
	 */
	function handlePop() {
		const nextAction = Action.Pop;
		if (blockedPopTx) {
			if (allowTx(blockedPopTx.action, blockedPopTx.location, () => blockedPopTx?.retry())) {
				listeners.call(blockedPopTx);
			}
			blockedPopTx = null;
		} else if (blockers.length) {
			const [nextIndex, nextLocation] = getIndexAndLocation();
			if (nextIndex != null) {
				const delta = index! - nextIndex;
				if (delta !== 0) {
					// Revert the POP
					blockedPopTx = {
						action: nextAction,
						location: nextLocation,
						retry() {
							go(delta * -1);
						}
					};

					go(delta);
				}
			} else {
				// Trying to POP to a location with no index. We did not create
				// this location, so we can't effectively block the navigation.
				console.warn(
					`You are trying to block a POP navigation to a location that was not ` +
						`created by the history library. The block will fail silently in ` +
						`production, but in general you should do all navigation with the ` +
						`history library (instead of using window.history.pushState directly) ` +
						`to avoid this situation.`
				);
			}
		} else {
			applyTx(nextAction);
		}
	}

	window.addEventListener('popstate', handlePop);
	window.addEventListener('hashchange', () => {
		// Ignore navigation event as it will be handled by the following popstate event
		if (blockedPopTx) {
			return;
		}
		const [, nextLocation] = getIndexAndLocation();

		// Ignore extraneous hashchange events.
		if (createPath(nextLocation) !== createPath(location)) {
			handlePop();
		}
	});

	let action = Action.Pop;
	let [index, location] = getIndexAndLocation();
	const listeners = createEvents<Update, Listener>();
	const blockers = createEvents<Transition, Blocker>();

	if (index == null) {
		index = 0;
		globalHistory.replaceState({ ...globalHistory.state, idx: index }, '');
	}

	function createHref(to: To) {
		let artificialPath: string;
		if (typeof to === 'string') {
			artificialPath = to;
		} else {
			artificialPath = createPath(to);
		}
		return getBaseHref() + artificialPathToUrl(artificialPath);
	}

	function getNextLocation(to: To, state: State = null): Location {
		return {
			...location,
			...toPartialPath(to),
			state,
			key: createKey()
		};
	}

	function getHistoryStateAndUrl(nextLocation: Location, index: number): [HistoryState, string] {
		return [
			{
				usr: nextLocation.state,
				key: nextLocation.key,
				idx: index
			},
			createHref(nextLocation)
		];
	}

	function allowTx(action: Action, location: Location, retry: () => void) {
		if (!blockers.length) {
			return true;
		}
		blockers.call({ action, location, retry });
		return !blockers.length;
	}

	function applyTx(nextAction: Action) {
		action = nextAction;
		[index, location] = getIndexAndLocation();
		listeners.call({ action, location });
	}

	function push(to: To, state?: State) {
		const nextAction = Action.Push;
		const nextLocation = getNextLocation(to, state);

		function retry() {
			push(to, state);
		}

		if (allowTx(nextAction, nextLocation, retry)) {
			const [historyState, url] = getHistoryStateAndUrl(nextLocation, index! + 1);

			// Try...catch because iOS limits us to 100 pushState calls :/
			try {
				globalHistory.pushState(historyState, '', url);
			} catch (error) {
				// They are going to lose state here, but there is no real
				// way to warn them about it since the page will refresh...
				window.location.assign(url);
			}

			applyTx(nextAction);
		}
	}

	function replace(to: To, state?: State) {
		const nextAction = Action.Replace;
		const nextLocation = getNextLocation(to, state);

		function retry() {
			replace(to, state);
		}

		if (allowTx(nextAction, nextLocation, retry)) {
			const [historyState, url] = getHistoryStateAndUrl(nextLocation, index!);

			globalHistory.replaceState(historyState, '', url);

			applyTx(nextAction);
		}
	}

	function go(delta: number) {
		globalHistory.go(delta);
	}

	return {
		get action() {
			return action;
		},
		get location() {
			return location;
		},
		createHref,
		push,
		replace,
		go,
		back() {
			go(-1);
		},
		forward() {
			go(1);
		},
		listen(listener) {
			return listeners.push(listener);
		},
		block(blocker) {
			const unblock = blockers.push(blocker);

			function promptBeforeUnload(event: BeforeUnloadEvent) {
				// Workaround for PageNavigationHook which always blocks and
				// just checks for whether it actually should block on unload
				// @ts-ignore
				blockers.call({ action, location, retry: () => void 0, noConfirm: true });
				if (!blockers.length) {
					return;
				}
				// Cancel the event.
				event.preventDefault();
				// Chrome (and legacy IE) requires returnValue to be set.
				event.returnValue = '';
			}

			if (blockers.length === 1) {
				window.addEventListener('beforeunload', promptBeforeUnload);
			}

			return function () {
				unblock();

				// Remove the beforeunload listener so the document may
				// still be salvageable in the pagehide event.
				// See https://html.spec.whatwg.org/#unloading-documents
				if (!blockers.length) {
					window.removeEventListener('beforeunload', promptBeforeUnload);
				}
			};
		}
	};
}
