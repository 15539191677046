/** The formatting options for dates. */

export type DateFormat = 'DATE_WITH_TIME' | 'DATE' | 'TIME' | 'TIME_SERIES' | 'DD.MM.YYYY' | 'YYYY-MM-DD';

/** Utility methods for date handling. */
export class DateUtils {
	/** Regex for checking for a 'HH:mm' time string */
	private static readonly TIME_REGEX = /^(\d|0\d|1\d|2[0-3]):[0-5]\d$/;

	/** List of the day ordinal suffixes. */
	private static readonly DAY_ORDINALS = ['th', 'st', 'nd', 'rd'];

	/** Number of milliseconds per second. */
	private static readonly MILLISECONDS_PER_SECOND = 1000;

	/** Number of milliseconds per minute. */
	private static readonly MILLISECONDS_PER_MINUTE = 60 * DateUtils.MILLISECONDS_PER_SECOND;

	/** Number of milliseconds per hour. */
	private static readonly MILLISECONDS_PER_HOUR = 60 * DateUtils.MILLISECONDS_PER_MINUTE;

	/** Number of milliseconds per day. */
	private static readonly MILLISECONDS_PER_DAY = 24 * DateUtils.MILLISECONDS_PER_HOUR;

	private static readonly MONTH_ONLY_FORMAT = new Intl.DateTimeFormat('en-US', {
		month: 'short'
	});

	private static readonly YEAR_ONLY_FORMAT = new Intl.DateTimeFormat('en-US', {
		year: 'numeric'
	});

	private static readonly FORMAT_DATE_TIME_FORMAT = new Intl.DateTimeFormat('en-US', {
		month: 'short',
		day: '2-digit',
		year: 'numeric',
		minute: '2-digit',
		hour: '2-digit',
		hourCycle: 'h23'
	});

	private static readonly SHORT_MONTH_ONLY_FORMAT = new Intl.DateTimeFormat('en-US', { month: 'short' });

	/**
	 * Formats the given timestamp as a string.
	 *
	 * @param timestamp The timestamp in milliseconds since 1970
	 * @returns The formatted timestamp If the given timestamp is null, will return 'Now'
	 */
	public static formatTimestamp(timestamp: number | string | null): string {
		const timestampNumber = Number(timestamp);
		if (timestamp == null || isNaN(timestampNumber) || timestampNumber >= Number.MAX_SAFE_INTEGER) {
			return 'Now';
		}
		const date = new Date(timestampNumber);
		const today = new Date();
		const todayString = DateUtils.format(date, 'DATE');
		if (todayString === DateUtils.format(today, 'DATE')) {
			return 'Today ' + DateUtils.format(date, 'TIME');
		}
		const yesterday = new Date(today.getTime() - DateUtils.MILLISECONDS_PER_DAY);
		if (todayString === DateUtils.format(yesterday, 'DATE')) {
			return 'Yesterday ' + DateUtils.format(date, 'TIME');
		}
		return DateUtils.format(date, 'DATE_WITH_TIME');
	}

	/** Formats the given date in the given format. */
	public static format(date: Date, format: DateFormat): string {
		switch (format) {
			case 'DATE_WITH_TIME':
				return DateUtils.formatDateTime(date);
			case 'DATE':
				return DateUtils.formatAsFullDate(date);
			case 'TIME':
				return DateUtils.formatAsTime(date);
			case 'TIME_SERIES':
				return DateUtils.formatDateWithSeconds(date);
			case 'DD.MM.YYYY':
				return DateUtils.formatDaysMonthYear(date);
			case 'YYYY-MM-DD':
				return DateUtils.formatAsIsoDate(date);
			default:
				throw new Error('Invalid format: ' + format);
		}
	}

	private static formatAsIsoDate(date: Date): string {
		const inIsoFormatWithTime = date.toISOString();
		return inIsoFormatWithTime.substring(0, inIsoFormatWithTime.indexOf('T'));
	}

	/** Formats the given date as "YYYYDDMM" for file names */
	public static formatForFileName(date: Date): string {
		const year = date.getFullYear();
		const day = DateUtils.ensure2Digits(date.getDate());
		const month = DateUtils.ensure2Digits(date.getMonth() + 1);
		return `${year}${day}${month}`;
	}

	private static formatDaysMonthYear(date: Date): string {
		return date.toLocaleDateString('de-DE', {
			day: '2-digit',
			year: 'numeric',
			month: '2-digit'
		});
	}

	/** Formats the given timestamp as date with time */
	public static formatDateTime(date: Date, isUTC = false): string {
		if (isUTC) {
			const month = this.SHORT_MONTH_ONLY_FORMAT.format(DateUtils.createDateAsUTC(date));
			return `${month} ${DateUtils.ensure2Digits(
				date.getUTCDate()
			)} ${date.getUTCFullYear()} ${DateUtils.ensure2Digits(date.getUTCHours())}:${DateUtils.ensure2Digits(
				date.getUTCMinutes()
			)}`;
		}
		return this.FORMAT_DATE_TIME_FORMAT.format(date).replace(/,/g, '');
	}

	/** Returns detailed version of a date and time */
	public static formatDateTimeDetailed(date: Date, hasTime: boolean): string {
		if (hasTime) {
			return date
				.toLocaleDateString('en-US', {
					year: 'numeric',
					month: 'short',
					day: '2-digit',
					weekday: 'short',
					hourCycle: 'h23',
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit'
				})
				.replace(/,/g, '');
		}

		return date
			.toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'short',
				day: '2-digit',
				weekday: 'short'
			})
			.replace(/,/g, '');
	}

	private static formatDateWithSeconds(date: Date): string {
		const dateString =
			date.getFullYear() +
			'-' +
			DateUtils.ensure2Digits(date.getMonth() + 1) +
			'-' +
			DateUtils.ensure2Digits(date.getDate());
		return dateString + ' ' + DateUtils.formatAsTime(date) + ':' + DateUtils.ensure2Digits(date.getSeconds());
	}

	private static formatAsTime(date: Date): string {
		return date.toLocaleTimeString('en-GB', {
			hour: '2-digit',
			minute: '2-digit',
			hour12: false
		});
	}

	/**
	 * Parses a given datetime string to its timestamp.
	 *
	 * @param dateString The string representing the datetime
	 * @returns The timestamp
	 * @see formatTimestamp
	 */
	public static parseTimestampFromDate(dateString: string): number {
		const time = Date.parse(dateString);
		if (!isNaN(time)) {
			return time;
		}
		const split = dateString.split(' ');
		const date = new Date();
		if (dateString.includes('Today') || dateString.includes('Yesterday')) {
			if (dateString.includes('Yesterday')) {
				date.setTime(date.getTime() - DateUtils.MILLISECONDS_PER_DAY);
			}
			if (split[1]!.match(DateUtils.TIME_REGEX)) {
				const time = split[1]!.split(':');
				date.setHours(parseInt(time[0]!, 10));
				date.setMinutes(parseInt(time[1]!, 10));
			}
		}
		return date.getTime();
	}

	/**
	 * Formats the given timestamp as a string.
	 *
	 * @param timestamp The timestamp in milliseconds since 1970
	 * @returns The formatted timestamp
	 */
	public static formatTimestampToDate(timestamp: number): string {
		const date = new Date(timestamp);
		return DateUtils.format(date, 'DD.MM.YYYY');
	}

	/** Formats the given date as a full date with a day ordinal suffix, i.e. 'Apr 13th 2019'. */
	public static formatAsFullDate(date: Date): string {
		const ordinal = DateUtils.getDayOrdinal(date.getDate());

		return `${this.MONTH_ONLY_FORMAT.format(date)} ${date.getDate()}${ordinal} ${this.YEAR_ONLY_FORMAT.format(
			date
		)}`;
	}

	/**
	 * Returns the suitable day ordinal suffix if the day number is one of the first three ordinals (excluding the
	 * corner cases 11, 12 and 13). Otherwise, returns 'th'.
	 */
	private static getDayOrdinal(day: number) {
		if (day % 10 <= 3 && Math.floor(day / 10) !== 1) {
			return DateUtils.DAY_ORDINALS[day % 10]!;
		}
		return DateUtils.DAY_ORDINALS[0]!;
	}

	/**
	 * Formats the given amount of milliseconds as a string in minutes and seconds. Second are formatted to provide only
	 * 2 decimals.
	 */
	public static formatMilliSecondsAsMinutesAndSeconds(milliSeconds: number): string {
		const runtimeInSeconds = milliSeconds / 1000;
		if (runtimeInSeconds < 60) {
			return `${DateUtils.roundToSpecifiedDecimals(runtimeInSeconds % 60, 2)}s`;
		}
		const remainingSeconds = DateUtils.roundToSpecifiedDecimals(runtimeInSeconds % 60, 0);
		return `${(runtimeInSeconds / 60).toFixed(0)}m${DateUtils.ensure2Digits(remainingSeconds)}s`;
	}

	/** Returns a 2 digit string of a number. */
	private static ensure2Digits(value: number): string {
		if (value >= 10) {
			return value.toString();
		}
		return `0${value}`;
	}

	/** Returns a number rounded to have a maximum of specified decimals. */
	private static roundToSpecifiedDecimals(value: number, desiredDecimals: number): number {
		return +(Math.round(Number(value + 'e+' + desiredDecimals)) + 'e-' + desiredDecimals);
	}

	/**
	 * Calculates the timestamp for today minus the given number of days in milliseconds. An optional reference
	 * timestamp can be supplied to subtract the days from. If no reference timestamp is supplied the current time is
	 * used.
	 *
	 * @param days The number of days from today.
	 * @param referenceTimestamp An optional reference timestamp subtract the days from.
	 * @returns A timestamp for the date from today minus the given number of days in milliseconds.
	 */
	public static getTimestampForDays(days: number, referenceTimestamp?: number | null): number {
		if (referenceTimestamp) {
			return referenceTimestamp - DateUtils.MILLISECONDS_PER_DAY * days;
		}
		return Date.now() - DateUtils.MILLISECONDS_PER_DAY * days;
	}

	/** Converts the date instance to a date instance with the same local date time but in UTC timezone. */
	private static createDateAsUTC(date: Date): Date {
		return new Date(
			Date.UTC(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				date.getHours(),
				date.getMinutes(),
				date.getSeconds(),
				date.getMilliseconds()
			)
		);
	}

	/** Converts the date instance to a format that is compatible with input elements of type date or datetime-local. */
	public static convertToDateInputISOString(date: Date | null, includeTime = true): string {
		if (date === null) {
			return '';
		}
		try {
			return DateUtils.createDateAsUTC(date)
				.toISOString()
				.substring(0, includeTime ? 16 : 10);
		} catch {
			// This can happen in browsers that do not support datetime-local (i.e. Firefox before version 93) as
			// intermediate date input returned from the inout might not be valid
			return '';
		}
	}

	/**
	 * Parses a user given date input to an actual Date class. It is able to handle a german data format "DD.MM.YYYY",
	 * in addition to any other format supported by Date.parse().
	 */
	public static parseUserInputDate(dateString: string, defaultDate: Date): Date {
		dateString = dateString.trim();
		const germanParts = dateString.match(/(\d{2})\.(\d{2})\.(\d{4})/);
		if (germanParts) {
			// Months are 0-based on JavaScript
			return new Date(parseInt(germanParts[2]!), parseInt(germanParts[1]!) - 1, parseInt(germanParts[0]!));
		}
		try {
			return new Date(Date.parse(dateString));
		} catch (error) {
			return defaultDate;
		}
	}
}
