import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import $ from 'jquery';
import * as UIUtilsTemplate from 'soy/commons/UIUtilsTemplate.soy.generated';
import { VERY_UNSAFE } from 'soyutils/soyutils_usegoog';
import { assertString } from 'ts-closure-library/lib/asserts/asserts';
import * as dom from 'ts-closure-library/lib/dom/dom';
import * as forms from 'ts-closure-library/lib/dom/forms';
import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType as EventsEventType } from 'ts-closure-library/lib/events/eventtype';
import { KeyCodes } from 'ts-closure-library/lib/events/keycodes';
import type { SafeHtml } from 'ts-closure-library/lib/html/safehtml';
import { HtmlSanitizer } from 'ts-closure-library/lib/html/sanitizer/htmlsanitizer';
import type { SanitizedCss, SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import { Renderer } from 'ts-closure-library/lib/soy/renderer';
import { HTML5LocalStorage } from 'ts-closure-library/lib/storage/mechanism/html5localstorage';
import { HTML5SessionStorage } from 'ts-closure-library/lib/storage/mechanism/html5sessionstorage';
import { Storage } from 'ts-closure-library/lib/storage/storage';
import * as strings from 'ts-closure-library/lib/string/string';
import * as style from 'ts-closure-library/lib/style/style';
import { Timer } from 'ts-closure-library/lib/timer/timer';
import { AdvancedTooltip } from 'ts-closure-library/lib/ui/advancedtooltip';
import { EventType } from 'ts-closure-library/lib/ui/component';
import type { Event as DialogEvent } from 'ts-closure-library/lib/ui/dialog';
import {
	ButtonSet,
	DefaultButtonCaptions,
	DefaultButtonKeys,
	DefaultButtons,
	Dialog,
	EventType as DialogEventType
} from 'ts-closure-library/lib/ui/dialog';
import { MenuItem } from 'ts-closure-library/lib/ui/menu';
import type { PopupMenu } from 'ts-closure-library/lib/ui/popupmenu';
import { Prompt } from 'ts-closure-library/lib/ui/prompt';
import { TableSorter } from 'ts-closure-library/lib/ui/tablesorter';
import type { Callback } from 'ts/base/Callback';
import * as soy from 'ts/base/soy/SoyRenderer';
import type { SoyTemplate } from 'ts/base/soy/SoyTemplate';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import type { TreemapAndTrendDialogBase } from 'ts/commons/dialog/TreemapAndTrendDialogBase';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import type { FlexCodeContainer } from 'ts/perspectives/metrics/code/FlexCodeContainer';
import type { PageButton } from './PageButton';
import { EPointInTimeType } from './time/EPointInTimeType';
import { tsdom } from './tsdom';
import type { Validator } from './Validator';

/** Event handler that processes a browser event. */
export type EventHandler = (event: BrowserEvent) => void | Promise<void>;

/** Utility methods for dealing with UI construction (mostly google ui). */
export class UIUtils {
	/** The key to store the value of the currently selected issue filter option in the localStorage. */
	public static ISSUE_FILTER_OPTION_KEY = 'issue-filter-option';

	/** Regex that matches a project id at the end of a string. */
	public static ENDS_WITH_PROJECT_ID_REGEX = / \[(.|\d|\w|-|_)*]$/;

	/** The internally used renderer. */
	public static RENDERER: Renderer = new Renderer();

	/** The internally used sanitizer. */
	public static SANITIZER: HtmlSanitizer = new HtmlSanitizer();

	/**
	 * Adds an item to a popup menu.
	 *
	 * @param popupMenu The menu to add the item to
	 * @param label The label of the item.
	 * @param clickHandler The event handler to be executed.
	 * @returns The item if additional methods have to be called on the item.
	 */
	public static addPopupItem(popupMenu: PopupMenu, label: string, clickHandler: EventHandler): MenuItem {
		const item = new MenuItem(label);
		popupMenu.addChild(item, true);
		events.listen<unknown, BrowserEvent>(item, EventType.ACTION, clickHandler);
		return item;
	}

	/** Returns a button set with save and cancel buttons */
	public static createSaveCancelButtonSet(): ButtonSet {
		const buttonSet = new ButtonSet();
		buttonSet.addButton(DefaultButtons.SAVE, true);
		buttonSet.addButton(DefaultButtons.CANCEL, false, true);
		return buttonSet;
	}

	/**
	 * Returns the value for the provided hash key or what is written in the storage, prefers the hash over the local
	 * storage. Returns default if none of them are set.
	 */
	public static getFromHashOrStorage(hashKey: string, storageKey: string, storageDefaultValue: string): string {
		const hash = NavigationHash.getCurrent();
		const issueFilterOption = hash.getString(hashKey);
		if (issueFilterOption != null) {
			return issueFilterOption;
		}
		return UIUtils.getFromStorageWithDefault(storageKey, storageDefaultValue);
	}

	/**
	 * Returns a new callback that prevents the default action of the event it receives as the first argument and then
	 * forwards all parameters to the given callback transparently.
	 *
	 * This is most often used to prevent links that have <code>href='#'</code> set from changing the location hash.
	 *
	 * @param callback The callback to decorate.
	 * @returns A decorated callback that prevents the default action of the event.
	 */
	public static preventDefaultEventAction(callback: EventHandler): (event: BrowserEvent) => void {
		return function (event, ...args): void {
			event.preventDefault();
			// @ts-ignore
			callback.apply(this, [event, ...args]);
		};
	}

	/**
	 * Sets up all file uploads rendered with UIUtilsTemplate.fileUpload in order to make them look like bootstrap
	 * buttons/text inputs but still preserving the file upload functionality.
	 *
	 * This is necessary since file upload inputs cannot be styled using CSS. Therefore, we put the upload inside a
	 * span, which looks like a button and make it cover the whole area of the span, while also being invisible. This
	 * means that anybody clicking the button will actually click the file upload element and trigger the file upload
	 * dialog.
	 *
	 * Furthermore, we add an input field to display the name of the selected file and add some listeners to refresh
	 * that input field.
	 *
	 * @deprecated This works automatically in the FileInput React component.
	 * @param element The element under which to look for file uploads.
	 * @param oldFileInputClone A copy of the file input containing the last valid file selection that the user made
	 */
	public static setupFileUploads(element: Element, oldFileInputClone: Record<string, HTMLInputElement> = {}): void {
		const inputChangeCallback = (inputField: HTMLInputElement, filenameField: HTMLInputElement): void => {
			if (ArrayUtils.isEmptyOrUndefined(inputField.files)) {
				// User pressed 'Cancel' in the File Dialog, restore the last file input if one was saved
				if (Object.keys(oldFileInputClone).includes(inputField.id)) {
					inputField.parentNode!.insertBefore(oldFileInputClone[inputField.id]!, inputField);
					inputField.remove();
					UIUtils.setupFileUploads(element, oldFileInputClone);
				}
				return;
			}
			oldFileInputClone[inputField.id] = $(inputField).clone(true)[0]!;
			UIUtils.clearUploadInput(inputField);
			const fileCount = inputField.files.length;
			if (fileCount > 1) {
				filenameField.value = '(' + fileCount + ') ';
			}
			Array.from(inputField.files).forEach((fileName, index) => {
				filenameField.value += fileName.name;
				if (index < fileCount - 1) {
					filenameField.value += '; ';
				}
			});
		};
		const uploadElements = element.querySelectorAll('.fileupload');
		for (const uploadElement of uploadElements) {
			const inputFieldOfElement = tsdom.getElementByTagNameAndClass('input', 'fileinput', uploadElement);
			const fileNameElement = tsdom.getElementByTagNameAndClass('input', 'filename', uploadElement);
			events.listen(inputFieldOfElement, EventsEventType.CHANGE, () =>
				inputChangeCallback(inputFieldOfElement, fileNameElement)
			);
		}
	}

	/**
	 * Clears the (hidden) input field of the given file upload input field.
	 *
	 * @param inputField The visible input field
	 */
	public static clearUploadInput(inputField: Element): void {
		const parentElement = inputField.parentElement?.parentElement;
		const hiddenInput = parentElement?.querySelector('.filename');
		if (hiddenInput !== undefined) {
			forms.setValue(hiddenInput, null);
		}
	}

	/** Returns true if none of the given checkboxes is selected. */
	public static isNoneSelected(checkboxes: ArrayLike<Element>): boolean {
		for (let i = 0; i < checkboxes.length; ++i) {
			if (forms.getValue(checkboxes[i]) != null) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Configures the dialog size with a border of 100 on each edge from the viewport size. The dialog is disposed when
	 * hidden.
	 *
	 * @param dialog The dialog to configure.
	 */
	public static configureDialog(dialog: Dialog): void {
		const width = dom.getViewportSize().width - 200;
		const height = dom.getViewportSize().height - 200;
		const content = dialog.getContentElement()! as HTMLElement;
		content.style.maxWidth = width + 'px';
		content.style.maxHeight = height + 'px';
		content.style.overflow = 'auto';
		dialog.setDisposeOnHide(true);
	}

	/**
	 * Configures an information dialog with a border of 100 on each edge from the viewport size. The dialog has no
	 * buttons.
	 *
	 * @param dialog The dialog to configure.
	 */
	public static configureAndShowInfoDialog(dialog: Dialog): void {
		UIUtils.configureDialog(dialog);
		dialog.setButtonSet(null);
		dialog.setVisible(true);
	}

	/**
	 * Configures a dialog with a border of 100 on each edge from the viewport size.
	 *
	 * @param dialog The dialog to configure.
	 */
	public static configureAndShowDialog(dialog: Dialog): void {
		UIUtils.configureDialog(dialog);
		dialog.setVisible(true);
	}

	/**
	 * Hooks a submission listener for a file upload that disables the button, which submits the form, as long as there
	 * is no file selected.
	 *
	 * @param submitButton Button which submits the upload.
	 * @param inputElement Input containing the file name.
	 */
	public static hookDisableUploadSubmissionListener(submitButton: Element, inputElement: HTMLInputElement): void {
		tsdom.disableElement(submitButton, true);
		events.listen(inputElement, EventsEventType.CHANGE, () => {
			if (inputElement.files !== null && inputElement.files.length > 0) {
				tsdom.disableElement(submitButton, false);
			}
		});
	}

	/**
	 * Add scroll support using the Home and End buttons. Listens on the entire page and scrolls the given container.
	 *
	 * @param container The container to scroll
	 */
	public static addHomeAndEndScrollSupport(container: FlexCodeContainer): void {
		events.listen(document.body, EventsEventType.KEYDOWN, function (event: BrowserEvent) {
			// Do not activate key bindings when in input fields
			if (dom.isElement(event.target) && /textarea|select|input/i.test(event.target.nodeName)) {
				return;
			}
			if (event.keyCode === KeyCodes.HOME) {
				container.getScrollContainerElement().scrollTop = 0;
			} else if (event.keyCode === KeyCodes.END) {
				container.getScrollContainerElement().scrollTop = container.getScrollContainerElement().scrollHeight;
			}
		});
	}

	/** Determines whether the given element is potentially scrollable vertically. */
	public static isVerticallyScrollable(element: Element): boolean {
		return (
			element.scrollHeight > element.clientHeight ||
			['scroll', 'auto'].includes(getComputedStyle(element).overflowY)
		);
	}

	/** Determines whether the given element is visible on the screen. */
	public static checkVisible(element: Element): boolean {
		const rootNode = document.documentElement;
		const rect = element.getBoundingClientRect();
		const viewHeight = Math.max(rootNode.clientHeight, window.innerHeight);
		const elementPartOfCurrentPage = document.documentElement.contains(element);

		return elementPartOfCurrentPage && !(rect.bottom < 0 || rect.top - viewHeight >= 0);
	}

	/**
	 * Sorts the given elements. Will use descending numericSort for numbers and ascending alphaSort for anything else.
	 *
	 * @param a Comparison element
	 * @param b Comparison element
	 */
	public static smartSort(a: string | number, b: string | number): number {
		if (strings.isNumeric(a) && strings.isNumeric(b)) {
			return TableSorter.createReverseSort(TableSorter.numericSort)(a, b);
		}
		return TableSorter.alphaSort(a, b);
	}

	/**
	 * Registers a listener for multiple buttons.
	 *
	 * @param selectorClassName Name of the CSS class used to select the buttons.
	 * @param parameterDataName The name of the data attribute that contains a parameter value to be passed to the event
	 *   handler.
	 * @param listener The listener to be registered. It will obtain the parameter loaded from the data attribute as
	 *   first parameter, the underlying event as second.
	 * @param thisReference The reference to be used as this for the listener.
	 * @param parentElement
	 */
	public static registerMultipleButtonListener(
		selectorClassName: string,
		parameterDataName: string,
		listener: (dataValue: string, event: BrowserEvent) => void,
		parentElement?: Element
	): void {
		UIUtils.registerListenerToAllWithClass(
			selectorClassName,
			EventsEventType.CLICK,
			(targetElement, event) => {
				listener(
					assertString(
						targetElement.dataset[parameterDataName],
						`targetElement is missing non null data-${parameterDataName} attribute!`
					),
					event
				);
			},
			true,
			parentElement
		);
	}

	/**
	 * Binds the listener for the events of the given event type to all elements which have a class of the given class
	 * name. Calls the listener with the target element.
	 */
	public static registerListenerToAllWithClass(
		className: string,
		eventType: EventsEventType,
		listener: (target: HTMLElement, event: BrowserEvent) => void,
		preventDefault = false,
		parentElement: Element | Document = document
	): void {
		let callback = (e: BrowserEvent): void => listener(e.currentTarget as HTMLElement, e);
		if (preventDefault) {
			callback = UIUtils.preventDefaultEventAction(callback);
		}
		for (const element of parentElement.querySelectorAll('.' + className)) {
			events.listen(element, eventType, callback);
		}
	}

	/** @returns The local storage */
	public static getLocalStorage(): Storage {
		return new Storage(new HTML5LocalStorage(null));
	}

	/** @returns The session storage */
	public static getSessionStorage(): Storage {
		return new Storage(new HTML5SessionStorage(null));
	}

	/**
	 * Returns a value from the local storage or the given default value if the storage does not contain the key.
	 *
	 * @param key For local storage.
	 * @param defaultValue Default value
	 * @returns Stored or default value
	 */
	public static getFromStorageWithDefault<T>(key: string, defaultValue: T[]): T[];
	public static getFromStorageWithDefault(key: string, defaultValue: boolean): boolean;
	public static getFromStorageWithDefault(key: string, defaultValue: string): string;
	public static getFromStorageWithDefault(key: string, defaultValue: number): number;
	public static getFromStorageWithDefault<T>(key: string, defaultValue: T): T {
		const storedValue = UIUtils.getLocalStorage().get(key);
		if (storedValue != null) {
			return storedValue;
		}
		return defaultValue;
	}

	/** @param key For local storage. */
	public static getLimitToProfileStorageKey(key: string): string {
		return UIUtils.getLocalStorage().get('limit-metrics-table-to-profile-' + key);
	}

	/**
	 * Shortens a trend text (created by a TimeParameter) in order to be displayed redundancy-free and space-saving.
	 *
	 * @param trendText The original trend text.
	 * @param type The type of the trend (e.g. 'baseline').
	 */
	public static shortenTrendText(trendText: string, type: EPointInTimeType): string {
		if (type === EPointInTimeType.BASELINE) {
			if (trendText.startsWith('Baseline:')) {
				trendText = trendText.replace('Baseline:', '');
			}
			const projectIdOffset = trendText.search(UIUtils.ENDS_WITH_PROJECT_ID_REGEX);
			if (projectIdOffset > 0) {
				trendText = trendText.substring(0, projectIdOffset);
			}
		} else if (type === EPointInTimeType.REVISION) {
			// Three dots (...) separate the revision number from the additional
			// revision information.
			const infoSeparatorOffset = trendText.search(/\.\.\./);
			if (infoSeparatorOffset > 0) {
				trendText = trendText.substring(0, infoSeparatorOffset);
			}
			trendText = trendText.replace(':', '');
		}
		return trendText;
	}

	/**
	 * Filters the ENTER key in the keydown and keypress event handlers of the specified dialog. This allows to create
	 * dialogs which won't close when pressing enter.
	 *
	 * @param dialog Dialog to be altered.
	 */
	public static dialogFilterEnter(dialog: Dialog): void {
		const dialogElement = dialog.getElement();
		// @ts-ignore Ignore that getHandler() is protected
		const handler = dialog.getHandler();
		// @ts-ignore ignore that onKey_ is private
		handler.unlisten(dialogElement, EventsEventType.KEYDOWN, dialog.onKey_);
		// @ts-ignore ignore that onKey_ is private
		handler.unlisten(dialogElement, EventsEventType.KEYPRESS, dialog.onKey_);
		const filterEnter = function (event: BrowserEvent): void {
			if (event.keyCode !== KeyCodes.ENTER) {
				// @ts-ignore Call original handler (ignore that onKey_ is private)
				return dialog.onKey_(event);
			}
		};
		handler.listen(dialogElement, EventsEventType.KEYDOWN, filterEnter);
		handler.listen(dialogElement, EventsEventType.KEYPRESS, filterEnter);
	}

	/**
	 * Attaches a click listener to an element identified by ID.
	 *
	 * @param id The id of the element to add the listener to.
	 * @param listener The listener to hook.
	 */
	public static addClickListenerForId(id: string, listener: EventHandler): void {
		const element = tsdom.getElementById(id);
		events.listen(element, EventsEventType.CLICK, UIUtils.preventDefaultEventAction(listener));
	}

	/**
	 * Displays the pop-up help dialog for help menu item.
	 *
	 * @param title The popup title text.
	 * @param template The Soy template defining the popup's content.
	 * @param data The data for the template
	 */
	public static showHelpPopup<T>(title: string, template: SoyTemplate<T>, data: T): void {
		const dialog = new Dialog();
		dialog.setTitle(title);
		dialog.setDisposeOnHide(true);
		dialog.setButtonSet(ButtonSet.createOk());
		const content = soy.renderAsElement(template, data);
		dialog.getContentElement()?.appendChild(content);
		dialog.setVisible(true);
	}

	/**
	 * Hooks the listeners to all line reference elements in the given element tree.
	 *
	 * @param containerElement The element to search for reference elements.
	 * @param callback The callback function handling a line click.
	 */
	public static hookLineReferenceListeners(containerElement: Element, callback: EventHandler): void {
		const references = containerElement.querySelectorAll('.a-ref-line');
		for (const reference of references) {
			events.listen(reference, EventsEventType.CLICK, UIUtils.preventDefaultEventAction(callback));
		}
	}

	/**
	 * Hides the given element using a fade-out effect.
	 *
	 * @param element The element to hide.
	 */
	public static fadeOutElement(element: HTMLElement): void {
		let opacity = 1;
		const timer = setInterval(function () {
			if (opacity <= 0.1) {
				clearInterval(timer);
				tsdom.setElementShown(element, false);
			}
			style.setOpacity(element, opacity);
			(element.style as { filter: string }).filter = 'alpha(opacity=' + opacity * 100 + ')';
			opacity -= opacity * 0.1;
		}, 50);
	}

	/**
	 * This wraps a zippy content element in a div with top and bottom padding of 1px to prevent overlapping margins and
	 * therefore also prevent jumpy animations.
	 *
	 * @param zippyContentElement The content element of the zippy which is animated. Corresponds to the element passed
	 *   to the zippy.
	 * @returns The wrapped element.
	 */
	public static wrapElementToPreventJumpyAnimations(zippyContentElement: Element): Element {
		// Margins determine how far away another element must be. They may overlap
		// however. When a new element becomes visible between two elements with
		// overlapping margins the animation might jump to ensure the newly visible
		// element has enough distance w.r.t. the element margins. Using a minimal
		// vertical padding on a wrapper element prevents this.
		const wrapper = soy.renderAsElement(UIUtilsTemplate.divWithClass, { styleClass: 'minimal-vertical-padding' });
		wrapper.appendChild(zippyContentElement);
		return wrapper;
	}

	/**
	 * Create a popup by getting the giving content element and adding into the container element.
	 *
	 * @param containerElement The element that contains the popup element
	 * @param content The content that is added into the container element.
	 */
	public static createPopup(containerElement: HTMLElement, content: SafeHtml, className?: string): AdvancedTooltip {
		const tooltip = new AdvancedTooltip(containerElement);
		tooltip.className += ' top ui popup vertical borderless menu ' + className;
		tooltip.setCursorTracking(true);
		tooltip.setShowDelayMs(25);
		tooltip.setCursorTrackingHideDelayMs(500);
		tooltip.setSafeHtml(content);
		return tooltip;
	}

	/**
	 * Fades out the given element after the given timespan
	 *
	 * @param id The id of the target element.
	 * @param milliSecs The delay time in milliseconds.
	 */
	public static hideElementDelayed(id: string, milliSecs: number): void {
		const element = document.getElementById(id);
		if (element === null) {
			return;
		}
		setTimeout(() => UIUtils.fadeOutElement(element), milliSecs);
	}

	/** Determines if the control key or the command key of Mac has been pressed. */
	public static isCtrlKey(event: BrowserEvent): boolean {
		return event.ctrlKey || event.metaKey;
	}

	/**
	 * Creates a timer that will call the given callback every x milliseconds.
	 *
	 * @param interval The refresh interval in milliseconds.
	 * @param callback The callback to call when a timer tick occurred.
	 * @param startNow Whether to immediately start the timer, default is false.
	 * @returns The timer object.
	 */
	public static createPeriodicRefreshTimer(interval: number, callback: () => void, startNow?: boolean): Timer {
		const timer = new Timer(interval);
		events.listen(timer, Timer.TICK, callback);
		if (startNow) {
			timer.start();
		}
		return timer;
	}

	/**
	 * Reset the timer.
	 *
	 * @param timer The timer object
	 */
	public static stopTimer(timer: Timer | null): void {
		if (timer != null) {
			timer.stop();
		}
	}

	/**
	 * Returns a sanitized html version of the given content. This should only be used rarely as this may introduce
	 * security breaches if not used properly.
	 *
	 * @deprecated Very insecure. Use renderAsSafeHtml or asSafeHtml instead.
	 * @param content The content
	 */
	public static sanitizedHtml(content: unknown): SanitizedHtml {
		return VERY_UNSAFE.ordainSanitizedHtml(content);
	}

	/**
	 * Returns a sanitized css version of the given content. This should only be used rarely as this may introduce
	 * security breaches if not used properly.
	 *
	 * @deprecated Very insecure. Use renderAsSafeHtml or asSafeHtml instead.
	 * @param content The content
	 */
	public static sanitizedCss(content: unknown): SanitizedCss {
		return VERY_UNSAFE.ordainSanitizedCss(content);
	}

	/**
	 * Renders the given template with the given parameters as safe html object.
	 *
	 * @param template The template to be rendered
	 * @param parameters The template parameters
	 */
	public static renderAsSafeHtml<T>(template: SoyTemplate<T>, parameters: T): SafeHtml {
		return UIUtils.RENDERER.renderSafeHtml(template, parameters);
	}

	/** Returns the sanitized safe html representation of the given content. */
	public static asSafeHtml(content: string | null): SafeHtml {
		if (content === null) {
			return UIUtils.SANITIZER.sanitize('null');
		}
		return UIUtils.SANITIZER.sanitize(content);
	}

	/** Create a dialog with an ok and a cancel button. */
	public static createDialog(title: string): Dialog {
		const dialog = new Dialog();
		dialog.setTitle(title);
		dialog.setButtonSet(ButtonSet.createOkCancel());
		dialog.setDisposeOnHide(true);
		return dialog;
	}

	/** Create a dialog with a save and a cancel button. */
	public static createSaveDialog(title: string): Dialog {
		const buttonSet = new ButtonSet()
			.addButton(DefaultButtons.SAVE, true)
			.addButton(DefaultButtons.CANCEL, false, true);
		const dialog = new Dialog();
		dialog.setTitle(title);
		dialog.setButtonSet(buttonSet);
		dialog.setDisposeOnHide(true);
		return dialog;
	}

	/** Create a dialog with an ok and a cancel button. */
	public static createDialogWithContent<T>(title: string, template: SoyTemplate<T>, parameters?: T): Dialog {
		const dialog = UIUtils.createDialog(title);
		const dialogContent = soy.renderAsElement(template, parameters);
		dialog.getContentElement()?.appendChild(dialogContent);
		return dialog;
	}

	/**
	 * Shows an ok dialog with a text to the user.
	 *
	 * @param title Of the dialog
	 * @param text To show
	 */
	public static showInfoDialog(title: string, text: string): void {
		const dialog = new Dialog();
		dialog.setDisposeOnHide(true);
		dialog.setTitle(title);
		dialog.setButtonSet(ButtonSet.createOk());
		dom.setTextContent(dialog.getContentElement(), text);
		(dialog.getContentElement() as HTMLElement).style.maxWidth = '600px';
		dialog.setVisible(true);
	}

	/**
	 * Checks that an input text field is not empty and that the input text is unique and not already existing in a
	 * given list of strings.
	 *
	 * @param validator The validator used to validate the input.
	 * @param elementId The ID of the element to validate.
	 * @param fieldName The name of the validated field.
	 * @param allowInputChange Whether the input field may be changed.
	 * @param newTextValue The new text value inserted into the input field.
	 * @param availableTextValues Array of already existing values to which the new text value would be compared.
	 * @returns The validated inserted input text value.
	 */
	public static validateTextInputNotEmptyAndUnique(
		validator: Validator,
		elementId: string,
		fieldName: string,
		allowInputChange: boolean,
		newTextValue: string | null,
		availableTextValues: string[]
	): string {
		const textInputElement = document.getElementById(elementId);
		const textInputValue = String(forms.getValue(textInputElement) ?? '').trim();
		validator.checkNotEmpty(textInputValue, fieldName, textInputElement);
		if (newTextValue == null || allowInputChange) {
			validator.checkUnique(textInputValue, availableTextValues, fieldName, textInputElement);
		}
		return textInputValue;
	}

	/**
	 * Creates a @link{Prompt} (a dialog with a simple input field) using the Semantic UI style.
	 *
	 * @deprecated This method was replaced with the React component {@link SaveItemWithNameModal}
	 * @param dialogTitle
	 * @param labelText The text above the input field
	 * @param callback To call when the dialog was closed
	 * @param defaultValue Optional default value that should be in the text box when the prompt appears.
	 */
	public static createPrompt(
		dialogTitle: string,
		labelText: string,
		callback: Callback<string | null>,
		defaultValue?: string
	): Prompt {
		const prompt = new Prompt(dialogTitle, labelText, callback, defaultValue);
		prompt.createDom();
		prompt.setDisposeOnHide(true);
		const inputParent = prompt.getInputElement()!.parentElement!;
		inputParent.classList.add('ui', 'fluid', 'input');
		return prompt;
	}

	/**
	 * A more robust version of JSON.parse handles undefined properly. Parsing can fail in which case an error is
	 * thrown.
	 *
	 * @param json The JSON to parse.
	 * @returns Null if json is not a string.
	 */
	public static parseJsonRobust(json: string | null | undefined): object | null | number | string | boolean {
		if (json == null || json === '') {
			return json ?? null;
		}

		// JSON.parse fails if the json doesn't represent a valid JSON.
		return JSON.parse(assertString(json)) as object | number | string | boolean | null;
	}

	/** Creates a page with a header text to which further components can be appended. */
	public static createPage(container: Element, headerText: string, buttons: PageButton[] = []): Element {
		return UIUtils.createPageWithVariableWidth(container, headerText, false, buttons);
	}

	/** Creates a page with a header text to which further components can be appended and variable width. */
	public static createPageWithVariableWidth(
		container: Element,
		headerText: string,
		fullWidth: boolean,
		buttons: PageButton[] = []
	): Element {
		const page = soy.renderAsElement(UIUtilsTemplate.page, {
			caption: headerText,
			buttons,
			fullWidth
		});
		container.appendChild(page);
		return page;
	}

	/**
	 * Creates an UI container for displaying an error message related to no available data.
	 *
	 * In React use the NoDataMessage component.
	 */
	public static createNoDataMessage(message: string, title = 'No data to show'): Element {
		return soy.renderAsElement(UIUtilsTemplate.noDataMessage, {
			message,
			title
		});
	}

	/** Downloads a file for which the content is already available (i.e., no server call is needed). */
	public static downloadFileFromJavaScript(filename: string, content: string): void {
		const url = 'data:text/plain;charset=utf-8,' + encodeURIComponent(content);
		UIUtils.downloadFile(url, filename);
	}

	/** Downloads a file for. */
	public static downloadFile(url: string, fileName: string) {
		const link = document.createElement('a');
		link.href = url;
		link.download = fileName;
		link.click();
	}

	/**
	 * Sets the value for an input element to given value if the element has empty (or whitespace only) content.
	 *
	 * @param elementId The id of the input element.
	 * @param replacementValue The value to set to.
	 * @param defaultValue If this is present, this is a default value that also counts as "empty", i.e. is replaced
	 */
	public static setValueIfEmpty(elementId: string, replacementValue: string, defaultValue?: string | null): void {
		const element = document.getElementById(elementId);
		if (element == null) {
			return;
		}
		const currentValue = forms.getValue(element);
		if (
			typeof currentValue !== 'string' ||
			StringUtils.isEmptyOrWhitespace(currentValue) ||
			currentValue === defaultValue
		) {
			forms.setValue(element, replacementValue);
		}
	}

	/**
	 * Creates a dialog displaying the dependency signals (Simulink).
	 *
	 * @param source The dependency's source
	 * @param target The dependency's target
	 * @param signals The dependency's signals
	 */
	public static createSignalsDialog(source: string, target: string, signals: string[]): void {
		const dialog = new Dialog();
		dialog.setDisposeOnHide(true);
		dialog.setEscapeToCancel(true);
		dialog.setTitle(`Signals (${signals.length})`);
		const dialogContent = soy.renderAsElement(UIUtilsTemplate.simulinkSignalDialog, {
			source,
			target,
			signals,
			maxDialogContentHeight: window.innerHeight * 0.6,
			maxDialogContentWidth: window.innerWidth * 0.8
		});
		dialog.getContentElement()?.appendChild(dialogContent);
		dialog.setButtonSet(null);
		dialog.setVisible(true);
	}

	/**
	 * Shows an ok/cancel dialog to the user. If the question is answered with 'ok', the given action is executed. This
	 * is a replacement for the browser's built-in confirm function that better matches our design.
	 *
	 * @param question The question to ask to the user.
	 * @param okAction The function to execute in case of a positive answer (ok).
	 * @param cancelAction The function to execute in case of a negative answer (cancel).
	 * @param questionIsHtml Whether the passed question is HTML
	 * @param okLabelText The text to use for the 'OK' button, which can make choices more clear for the user (e.g.
	 *   'Reanalyze project' instead of just 'OK')
	 * @param cancelLabelText The text to use for the 'Cancel' button
	 */
	public static confirmAction(
		question: string,
		okAction: () => void | Promise<void>,
		cancelAction?: () => void | Promise<void>,
		questionIsHtml = false,
		okLabelText: string = DefaultButtonCaptions.OK,
		cancelLabelText: string = DefaultButtonCaptions.CANCEL
	): void {
		const dialog = new Dialog();
		dialog.setDisposeOnHide(true);
		dialog.setTitle('Confirmation needed');
		const buttonSet = new ButtonSet();
		buttonSet.addButton(
			{
				key: DefaultButtonKeys.OK,
				caption: okLabelText
			},
			true
		);
		buttonSet.addButton(
			{
				key: DefaultButtonKeys.CANCEL,
				caption: cancelLabelText
			},
			false,
			true
		);
		dialog.setButtonSet(buttonSet);
		if (questionIsHtml) {
			dialog.getContentElement()!.innerHTML = question;
		} else {
			dom.setTextContent(dialog.getContentElement(), question);
		}
		events.listen(dialog, DialogEventType.SELECT, function (e) {
			if (e.key === DefaultButtons.OK.key) {
				okAction();
			} else {
				cancelAction?.();
			}
		});
		(dialog.getContentElement() as HTMLElement).style.maxWidth = '600px';
		dialog.setVisible(true);
	}

	/** Appends the relative path from the (clicked) table element to the uniformPath. */
	public static extractPathFromElement(element: HTMLElement, uniformPath: string): string {
		const relativePath = element.dataset.relativePath;
		if (StringUtils.isEmptyOrWhitespace(uniformPath)) {
			return String(relativePath);
		}
		if (!StringUtils.isEmptyOrWhitespace(relativePath)) {
			if (uniformPath.endsWith('/')) {
				return uniformPath + relativePath;
			} else {
				return uniformPath + '/' + relativePath;
			}
		}
		return uniformPath;
	}

	/** Opens a dialog to show the treemap/trend after a table cell was clicked. */
	public static openMetricsTreeMap(dialog: TreemapAndTrendDialogBase, endCommit: UnresolvedCommitDescriptor): void {
		const startCommit = null;
		dialog.open();
		dialog.loadAndShowTreemap(startCommit, endCommit);
		dialog.prepareHistory(startCommit, endCommit);
	}

	/**
	 * Converts the data from a react-hook-form to a FormData format. Necessary to be able to properly pass files as
	 * request parameters.
	 */
	public static convertReactHookDataToFormData(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		reactHookData: Record<string, any>
	): FormData {
		const data = new FormData();
		for (const key in reactHookData) {
			if (reactHookData[key] instanceof Array) {
				for (const value of reactHookData[key]) {
					data.append(key, value);
				}
			} else if (key === 'field') {
				data.append(key, reactHookData[key][1]);
			} else {
				data.append(key, reactHookData[key]);
			}
		}
		return data;
	}

	/** Gets form data and fixes the Semantic UI checkbox values. */
	public static getFormData(exportBackupForm: HTMLFormElement): FormData {
		const formData = new FormData(exportBackupForm);
		formData.forEach((value, key, parent) => {
			if (typeof value === 'string') {
				if (value === 'on') {
					parent.set(key, 'true');
				} else if (value === 'off') {
					parent.set(key, 'false');
				}
			}
		});
		return formData;
	}

	/** Gets the tab HTML element. */
	public static getTabContentElement(tabTitle: string): HTMLElement | null {
		return document.querySelector(`div.tab-content[data-tab="tab-${tabTitle}"]`);
	}

	/**
	 * Returns a Dialog object with the given title and 3 action buttons for when Analysis Profile is to be saved and
	 * reanalysis is required
	 *
	 * @param title The title of the Dialog
	 * @param onReanalyze The Method to execute when project is saved and reanalyzed
	 * @param onDiscard The Method to execute when changes are to be discarded
	 * @param onCancel The Method to be executed when user wants to continue editing
	 */
	public static getProjectReanalysisRequiredDialog(
		title: string,
		onReanalyze: () => void,
		onDiscard: () => void,
		onCancel: () => void
	): Dialog {
		const dialog = new Dialog();
		dialog.setDisposeOnHide(true);
		dialog.setHasTitleCloseButton(false);
		const buttons = new ButtonSet();

		buttons.addButton({ key: 'reanalyze', caption: 'Save and re-analyze' }, true);
		buttons.addButton({ key: 'discard', caption: 'Discard changes' });
		buttons.addButton({ key: 'cancel', caption: 'Continue editing' });
		dialog.setButtonSet(buttons);
		dialog.setTitle(title);

		events.listen(dialog, DialogEventType.SELECT, (e: DialogEvent) => {
			if (e.key === 'reanalyze') {
				onReanalyze();
			} else if (e.key === 'discard') {
				onDiscard();
			} else if (e.key === 'cancel') {
				onCancel();
			}
		});

		dialog.setVisible(true);
		return dialog;
	}
}
