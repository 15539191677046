import * as arrays from 'ts-closure-library/lib/array/array';
import { contains, removeAt } from 'ts-closure-library/lib/array/array';
import { StringUtils } from 'ts/commons/StringUtils';

/**
 * Comparator interface that returns a negative number, zero, or a positive number as the first argument is less than,
 * equal to, or greater than the second, respectively.
 */
type Comparator<T> = (p1: T, p2: T) => number;

/** Utility methods for array handling. */
export class ArrayUtils {
	/**
	 * Removes the duplicates of a given array.
	 *
	 * @param input The input array
	 * @returns A duplicate-free version of the input array
	 */
	public static removeDuplicates<T>(input: T[]): T[] {
		return [...new Set(input)];
	}

	/**
	 * Removes the duplicates of a given array while preserving the order of the elements. In contrast to
	 * {@link #removeDuplicates} this method checks for deep equality and not for referential equality.
	 *
	 * @param input The input array
	 * @param equalsFunction A function that returns true if the two given arguments should be considered equal
	 */
	public static removeDuplicatesPreservingOrder<T>(
		input: T[],
		equalsFunction: (value1: T, value2: T) => boolean = (a: T, b: T): boolean => a === b
	): T[] {
		const result: T[] = [];
		for (const item of input) {
			if (!result.some(value => equalsFunction(value, item))) {
				result.push(item);
			}
		}
		return result;
	}

	/**
	 * See {@link #removeAllWithSet}, which should also be preferred if possible (faster).
	 *
	 * @template T
	 * @param allObjects Array of objects from which some objects should be removed.
	 * @param arrayToRemove Array of objects which should be removed from the specified array of all objects.
	 */
	public static removeAll<T>(allObjects?: T[] | null, arrayToRemove?: T[] | null): void {
		ArrayUtils.removeAllWithSet(allObjects, new Set(arrayToRemove));
	}

	/**
	 * Removes the elements of the give set from the first array. Note that this is more efficient that
	 * {@link #removeAll}.
	 *
	 * @template T
	 * @param allObjects Array of objects from which some objects should be removed.
	 * @param setToRemove Array of objects which should be removed from the specified array of all objects.
	 */
	public static removeAllWithSet<T>(
		allObjects: T[] | null | undefined,
		setToRemove: Set<T> | null | undefined
	): void {
		if (ArrayUtils.isEmptyOrUndefined(allObjects) || setToRemove == null || setToRemove.size === 0) {
			return;
		}

		// The following cannot be done with goog_array.forEach,
		// as it leads to an error.
		for (let i = allObjects.length - 1; i >= 0; i--) {
			if (setToRemove.has(allObjects[i]!)) {
				removeAt(allObjects, i);
			}
		}
	}

	/**
	 * Indicates whether the specified array is empty or undefined.
	 *
	 * @param collection Collection of objects to test.
	 * @return, if The specified array is undefined or empty; {@code false}, otherwise.
	 */
	public static isEmptyOrUndefined<S, T extends ArrayLike<S>>(
		collection: T | null | undefined | never[]
	): collection is null | undefined | never[] {
		return collection == null || collection.length === 0;
	}

	/** @returns True if potentialSubset is a subset of array, false otherwise. */
	public static arrayIsSubset<T>(potentialSubset: ArrayLike<T>, array: ArrayLike<T>): boolean {
		if (potentialSubset.length > array.length) {
			return false;
		}
		for (let i = 0; i < potentialSubset.length; i++) {
			if (!contains(array, potentialSubset[i]!)) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Calculates the sum of a numeric array.
	 *
	 * @param values The values to sum up.
	 */
	public static sum(values: number[]): number {
		return values.reduce((accumulatedSum, currentEntry) => accumulatedSum + currentEntry, 0);
	}

	/**
	 * Calculates the percentages of the values w.r.t. the sum of the values. Simple algorithm which doesn't take
	 * rounding errors into account. Therefore the actual sum of the percentages might be slightly off from 100.
	 *
	 * @param values The values to calculate the percentage for.
	 * @param optSum An optional sum to use for calculating the percentages.
	 * @returns The percentages corresponding to the values or null, if the sum of all entries is zero.
	 */
	public static toPercentages(values: number[], optSum?: number): number[] | null {
		const sum = optSum ?? ArrayUtils.sum(values);
		if (sum === 0) {
			return null;
		}
		return values.map(value => value / sum);
	}

	/**
	 * Tests whether the two given arrays have identical elements (regardless of the order of the arrays)
	 *
	 * @param array1 The array to search through.
	 * @param array2 The array to search through.
	 */
	public static haveIdenticalElements<T>(array1: ArrayLike<T> | T[], array2: ArrayLike<T> | T[]): boolean {
		return (
			array1.length === array2.length &&
			ArrayUtils.arrayIsSubset(array1, array2) &&
			ArrayUtils.arrayIsSubset(array2, array1)
		);
	}

	/**
	 * Adds the given items to the given existing array in place, so the first passed array will be changed. Duplicate
	 * elements are allowed in the resulting array.
	 */
	public static addAllToArray<T>(existingArray: T[], newItems: T[]): void {
		existingArray.push(...newItems);
	}

	/**
	 * Sorts the array using the comparators. Uses the return value of the first comparator to not return 0. If all
	 * comparators are 0 then the two elements are considered equal w.r.t. to the sort order.
	 */
	public static sort<T>(array: T[], comparators: Array<Comparator<T>>): T[] {
		const sortedArray = array.slice(0);
		sortedArray.sort(this.getCombinedComparator(comparators));
		return sortedArray;
	}

	/**
	 * Combines multiple comparators into one, by comparing the given values via the first to the last comparator until
	 * one is able to determine an order.
	 */
	public static getCombinedComparator<T>(comparators: Array<Comparator<T>>): Comparator<T> {
		return (first: T, second: T) => {
			for (let i = 0; i < comparators.length; i++) {
				const compareValue = comparators[i]!(first, second);
				if (comparators[i]!(first, second) !== 0) {
					return compareValue;
				}
			}
			return 0;
		};
	}

	/** Returns the intersection of both arrays as an array without duplicates. */
	public static intersection<T>(array1: T[], array2: T[]): T[] {
		const array1Set = new Set(array1);
		const intersectionSet: Set<T> = new Set();
		array2.forEach(element => {
			if (array1Set.has(element)) {
				intersectionSet.add(element);
			}
		});
		return [...intersectionSet];
	}

	/** Calculates the difference set between the two arrays without duplicates. */
	public static differenceSet<T>(minuendArray: T[] | null, subtrahendArray: T[] | null): T[] {
		const subtrahendSet = new Set(subtrahendArray);
		const safeMinuendArray = minuendArray || [];
		const result = safeMinuendArray.filter(x => !subtrahendSet.has(x));
		arrays.removeDuplicates(result);
		return result;
	}

	/** Adds the given item to the given array if the item is not null/undefined. */
	public static addItemIfDefAndNotNull<T>(array: T[], item: T | null | undefined): void {
		if (item != null) {
			array.push(item);
		}
	}

	/** Removes all elements of the array that are either null or undefined. */
	public static removeNullsOrUndefineds<T>(array: Array<T | null | undefined> | null): asserts array is T[] {
		if (array == null) {
			return;
		}
		arrays.removeAllIf(array, element => element == null);
	}

	/**
	 * Accumulates numbers, from right to left.
	 *
	 * Example: accumulateLeft([1, 2, 3, 4]) == [10, 6, 3, 1]
	 */
	public static accumulateLeft(array: number[]): number[] {
		const result = [];
		let sum = 0;
		for (let i = 0; i < array.length; i++) {
			sum += array[i]!;
			result[array.length - i - 1] = sum;
		}
		return result;
	}

	/**
	 * Compares its two arguments for order, using the built in < and > operators.
	 *
	 * @param a The first object to be compared.
	 * @param b The second object to be compared.
	 * @returns A negative number, zero, or a positive number as the first argument is less than, equal to, or greater
	 *   than the second, respectively.
	 */
	public static defaultCompare<T extends number | string | boolean>(a: T, b: T): number {
		if (a > b) {
			return 1;
		} else if (a < b) {
			return -1;
		} else {
			return 0;
		}
	}

	/**
	 * Returns a comparator function that compares objects of type T by the keys produced by applying the given key
	 * function to objects of type T.
	 */
	public static comparatorByKey<T, K extends string | number | boolean>(
		keyFunction: (object: T) => K
	): Comparator<T> {
		return function (a, b) {
			return ArrayUtils.defaultCompare(keyFunction(a), keyFunction(b));
		};
	}

	/** Returns the first element of the given array. If the array is not defined or empty, returns {@code undefined}. */
	public static getFirstOrUndefined<T>(array?: T[]): T | undefined {
		return ArrayUtils.getFirstOr(array ?? [], undefined);
	}

	/**
	 * Returns the first element of the given array. If the array is not defined or empty, returns {@param
	 * ifUnavailable}.
	 */
	public static getFirstOr<T>(array: T[], ifUnavailable: T): T {
		if (ArrayUtils.isEmptyOrUndefined(array)) {
			return ifUnavailable;
		}
		return array[0]!;
	}

	/**
	 * Groups the objects of the given array by the values returned by the keyFunction. For example:
	 *
	 *     const array = [{a: 'foo', b: 1}, {a: 'foo', b: 2}, {a: 'bar', b: 3}]
	 *     groupBy(array, item => item.a) returns
	 *     new Map<>([
	 *     ['foo', [{a: 'foo', b: 1}, {a: 'foo', b: 2}]],
	 *     ['bar', [{a: 'bar', b: 3}]]
	 *     ])
	 */
	public static groupBy<Type, GroupByValue extends string | number>(
		array: Type[],
		keyFunction: (item: Type) => GroupByValue
	): Map<GroupByValue, Type[]> {
		const result = new Map<GroupByValue, Type[]>();
		for (const item of array) {
			const valueToGroupBy = keyFunction(item);
			if (!result.has(valueToGroupBy)) {
				result.set(valueToGroupBy, []);
			}
			const list = result.get(valueToGroupBy)!;
			list.push(item);
		}
		return result;
	}

	/** Concatenate two nullable arrays to a non-null array with the union type of elements. */
	public static concatNullable<A, B>(arrayA: A[] | undefined, arrayB: B[] | undefined): Array<A | B> {
		if (!arrayA) {
			return arrayB ?? ([] as B[]);
		}

		if (!arrayB) {
			return arrayA;
		}

		return [...arrayA, ...arrayB];
	}

	/**
	 * Trims the given array `input` (removes empty elements on from the left and the right until an non-empty element
	 * appears).
	 *
	 * @param input - The input array to trim.
	 * @param isEmpty - The function to determine whether or not a given array element is considered empty.
	 */
	public static trimArray<T>(input: T[], isEmpty: (element: T) => boolean): T[] {
		function trimLeft(input: T[]): T[] {
			return input.reduce((prev, curr) => {
				if (prev.length > 0 || !isEmpty(curr)) {
					return [...prev, curr];
				}
				return prev;
			}, [] as T[]);
		}

		function trimRight(input: T[]): T[] {
			return input.reduceRight((prev, curr) => {
				if (prev.length > 0 || !isEmpty(curr)) {
					return [curr, ...prev];
				}
				return prev;
			}, [] as T[]);
		}

		return trimRight(trimLeft(input));
	}

	/**
	 * Filters the given list based on the given query, ignoring casing and leading/trailing whitespaces. If the query
	 * consists of multiple words (i.e., is divided by spaces), every query word has to be found in the (stringified)
	 * item, otherwise the item will be filtered out.
	 *
	 * @param itemStringifier Optional: A function that returns a list of properties of an individual item that will be
	 *   searched through. If not set, an item will to stringified using String(item).
	 */
	public static filterByQuery<T>(
		query: string,
		items: T[],
		itemStringifier: (item: T) => string[] = item => [String(item)]
	): T[] {
		if (StringUtils.isEmptyOrWhitespace(query)) {
			return items;
		}
		const lowercaseQueryParts = query.toLowerCase().trim().split(/\W+/);
		return items.filter(item => {
			const itemStrings = itemStringifier(item);
			return lowercaseQueryParts.every(queryPart =>
				itemStrings.some(itemString => itemString.toLowerCase().includes(queryPart))
			);
		});
	}

	/**
	 * Filters the values in the given record based on the given query, ignoring casing and leading/trailing
	 * whitespaces. If the query consists of multiple words (i.e., is divided by spaces), every query word has to be
	 * found in the (stringified) item, otherwise the item will be filtered out.
	 *
	 * @param itemStringifier Optional: A function that returns a list of properties of an individual item that will be
	 *   searched through. If not set, an item will to stringified using String(item).
	 */
	public static filterRecordByQuery<T>(
		query: string,
		items: Record<string, T>,
		itemStringifier: (item: T) => string[] = item => [String(item)]
	): Record<string, T> {
		if (StringUtils.isEmptyOrWhitespace(query)) {
			return items;
		}
		const lowercaseQueryParts = query.toLowerCase().trim().split(/\W+/);
		const filteredItems: Record<string, T> = {};
		Object.keys(items).forEach(itemKey => {
			const item = items[itemKey]!;
			const itemStrings = itemStringifier(item);
			const fulfillsQuery = lowercaseQueryParts.every(queryPart =>
				itemStrings.some(itemString => itemString.toLowerCase().includes(queryPart))
			);
			if (fulfillsQuery) {
				filteredItems[itemKey] = item;
			}
		});
		return filteredItems;
	}

	/**
	 * Maps the given array by the value of the given property of the individual objects. In case multiple items have
	 * the same value, the last occurrence will overwrite the previous value(s).
	 */
	public static mapByMember<T extends object>(array: T[], property: keyof T): Record<string, T> {
		const result = {};
		array.forEach(item => {
			// @ts-ignore
			result[item[property]] = item;
		});
		return result;
	}

	/**
	 * Sorts the given array by the value of the given property in ascending order.
	 *
	 * @param map Optional: A function that maps the values of the given property before they are compared.
	 */
	public static sortByMember<T extends object>(
		array: T[],
		property: keyof T,
		map?: (value: T[keyof T]) => T[keyof T]
	): T[] {
		return array.sort((a, b) => {
			let valueA = a[property];
			let valueB = b[property];
			if (map) {
				valueA = map(valueA);
				valueB = map(valueB);
			}
			if (valueA < valueB) {
				return -1;
			}
			if (valueA > valueB) {
				return 1;
			}
			return 0;
		});
	}

	/** Creates an array with the given size, containing 'undefined' entries. */
	public static createArrayWithSize(size: number): undefined[] {
		return Array.from({ length: size });
	}

	/**
	 * Creates an array with the items from {@link a} that are updated with the corresponding value from {@link lookup} if
	 * the item is contained in {@link lookup}. {@link property} specifies the member that is used to check whether an
	 * item is contained in {@link lookup}.
	 */
	public static updateArrayFromLookupByMember<T>(a: T[], lookup: Map<unknown, T>, property: keyof T): T[] {
		return a.map(item => {
			if (lookup.has(item[property])) {
				return lookup.get(item[property])!;
			}
			return item;
		});
	}
}
