/** Generated from EMetricValueType.java. Do not modify this file! */

import type { EAggregationEntry } from 'typedefs/EAggregation';

export type EMetricValueTypeEntry = 'NUMERIC' | 'TIMESTAMP' | 'ASSESSMENT' | 'COUNTER_SET' | 'STRING';

export class EMetricValueType {
	static readonly NUMERIC = new EMetricValueType(0, 'NUMERIC', ["SUM","MIN","MAX","NONE"]);
	static readonly TIMESTAMP = new EMetricValueType(1, 'TIMESTAMP', ["MIN","MAX","NONE"]);
	static readonly ASSESSMENT = new EMetricValueType(2, 'ASSESSMENT', ["SUM","NONE"]);
	static readonly COUNTER_SET = new EMetricValueType(3, 'COUNTER_SET', ["UNION","NONE"]);
	static readonly STRING = new EMetricValueType(4, 'STRING', ["NONE"]);
	static readonly values = [
		EMetricValueType.NUMERIC,
		EMetricValueType.TIMESTAMP,
		EMetricValueType.ASSESSMENT,
		EMetricValueType.COUNTER_SET,
		EMetricValueType.STRING
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EMetricValueTypeEntry,
		public readonly supportedAggregations: Array<EAggregationEntry>,

	){
	}

	toString() {
		return this.name;
	}
}

