import type { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import type { Rgb } from 'ts-closure-library/lib/color/color';
import { darken, hexToRgb, parse, rgbToHex } from 'ts-closure-library/lib/color/color';
import { setStyle } from 'ts-closure-library/lib/style/style';
import type { EFindingEnablementEntry } from 'typedefs/EFindingEnablement';
import { EFindingEnablement } from 'typedefs/EFindingEnablement';
import { ELanguage } from 'typedefs/ELanguage';

/** Utility methods for colors */
export class ColorUtils {
	/** 'Blue' as hex string. */
	public static BLUE = '#0000ff';

	/** Teamscale's main orange color as hex string. Should match the one in teamscale_variables.less */
	public static TEAMSCALE_ORANGE = '#fd7b34';

	/** Green defined in teamscale_variables.less */
	public static TEAMSCALE_GREEN = '#66CC66';

	/** Yellow defined in teamscale_variables.less */
	public static TEAMSCALE_YELLOW = '#FBBC05';

	/** Red defined in teamscale_variables.less */
	public static TEAMSCALE_RED = '#FF3333';

	/** White as hex string. */
	public static WHITE = '#FFF';

	/** A very light gray as hex string. */
	public static LIGHT_GRAY = '#aaa';

	/** Plotly's muted blue colour as hex string. */
	public static MUTED_BLUE = '#1f77b4';

	/** Plotly's safety orange colour as hex string. */
	public static SAFETY_ORANGE = '#ff7f0e';

	/** Plotly's cooked asparagus green colour as hex string. */
	public static COOKED_ASPARAGUS_GREEN = '#2ca02c';

	/** Plotly's brick red colour as hex string. */
	public static BRICK_RED = '#d62728';

	/** Plotly's muted purple colour as hex string. */
	public static MUTED_PURPLE = '#9467bd';

	/** Plotly's chestnut brown colour as hex string. */
	public static CHESTNUT_BROWN = '#8c564b';

	/** Plotly's raspberry yogurt pink colour as hex string. */
	public static RASPBERRY_YOGURT_PINK = '#e377c2';

	/** Plotly's middle gray colour as hex string. */
	public static MIDDLE_GRAY = '#7f7f7f';

	/** Plotly's curry yellow-green colour as hex string. */
	public static CURRY_YELLOW_GREEN = '#bcbd22';

	/** Plotly's blue-teal colour as hex string. */
	public static BLUE_TEAL = '#17becf';

	/** Greenish blue colour as hex string. */
	public static GREEN_BLUE = '#0aaf90';

	/** Cyan */
	public static CYAN = '#00ffff';

	/** See {@link ETrafficLightColor#RED}. */
	public static TRAFFIC_LIGHT_RED = '#FF3333';

	/** See {@link ETrafficLightColor#ORANGE}. */
	public static TRAFFIC_LIGHT_ORANGE = '#FF9966';

	/** See {@link ETrafficLightColor#YELLOW}. */
	public static TRAFFIC_LIGHT_YELLOW = '#FBBC05';

	/** See {@link ETrafficLightColor#GREEN}. */
	public static TRAFFIC_LIGHT_GREEN = '#00CC33';

	/** See {@link ETrafficLightColor#UNKNOWN}. */
	public static TRAFFIC_LIGHT_UNKNOWN = '#000000';

	/** Viridis color of the neutral category, corresponding to 'gray' in normal color mode. */
	public static readonly VIRIDIS_COLOR_CATEGORY_NEUTRAL = '#ddd';

	/** Viridis color of the first category, corresponding to 'green' in normal color mode. */
	public static readonly VIRIDIS_COLOR_CATEGORY_1 = '#fde725';

	/** Viridis color of the second category, corresponding to 'orange' in normal color mode. */
	public static readonly VIRIDIS_COLOR_CATEGORY_2 = '#21918c';

	/** Viridis color of the third category, corresponding to 'red' in normal color mode. */
	public static readonly VIRIDIS_COLOR_CATEGORY_3 = '#440154';

	/** Color names applicable to traffic light and test gap colors */
	public static readonly COLOR_NAMES = {
		GREEN: 'green',
		ORANGE: 'orange',
		YELLOW: 'yellow',
		RED: 'red',
		GRAY: 'gray'
	};

	/** The default colors for the color chooser. */
	private static readonly DEFAULT_COLORS = [
		'#639CCE',
		ColorUtils.TEAMSCALE_RED,
		ColorUtils.TEAMSCALE_YELLOW,
		ColorUtils.TEAMSCALE_GREEN,
		'#666666'
	];

	/** Returns a copy of the default colors for the color chooser. */
	public static getDefaultColorChooserColors(): string[] {
		// Copied to avoid accidental changes from somewhere else.
		return Array.from(ColorUtils.DEFAULT_COLORS);
	}

	/** Sets the background color of the given element. */
	public static setBackgroundColor(element: Element, hexColor: string): void {
		setStyle(element, 'background-color', hexColor);
	}

	/** @returns The assessment colors (green, yellow, red) as hex strings. */
	public static assessmentColors(): string[] {
		// Return new array to allow caller to make modifications, e.g. add
		// additional colors.
		return [ColorUtils.TEAMSCALE_GREEN, ColorUtils.TEAMSCALE_YELLOW, ColorUtils.TEAMSCALE_RED];
	}

	/**
	 * @param hexColor
	 * @param factor A number between 0 and 1, where 0 will not alter the input and 1 will return black.
	 */
	public static darken(hexColor: string, factor: number): string {
		const darkerRgb = darken(hexToRgb(hexColor), factor);
		return rgbToHex(darkerRgb[0]!, darkerRgb[1]!, darkerRgb[2]!);
	}

	/**
	 * @param rgbColor The color as string in rgb representation e.g. rgb(10,20,30)
	 * @param opacity The required opacity of the color, should be a value between 0 and 1
	 * @returns The rgba color code
	 */
	public static rgbToRgba(rgbColor: string, opacity: number): string {
		return rgbColor.slice(0, 3) + 'a' + rgbColor.slice(3, rgbColor.length - 1) + ', ' + opacity + ')';
	}

	/**
	 * Calculates the complementary color for a given color. Complementary colors are two colors which cancel each other
	 * out if mixed. {@see https://en.wikipedia.org/wiki/Complementary_colors}. Calculation is done by inverting the rgb
	 * color representation.
	 *
	 * @param color The color. Can be any css compatible color code.
	 * @returns The hex color code of the complementary color
	 */
	public static calculateComplementaryColor(color: string): string {
		const rgbColor: Rgb = hexToRgb(parse(color).hex);
		return rgbToHex(rgbColor[0]! ^ 0xff, rgbColor[1]! ^ 0xff, rgbColor[2]! ^ 0xff);
	}

	/** Determines whether the given RGB value is exactly white. */
	public static isWhite(color: Rgb) {
		return color[0] === 0xff && color[1] === 0xff && color[2] === 0xff;
	}

	/**
	 * Returns a SemanticUI color name for the given language, which can e.g. be used for colored language labels. The
	 * mapping is not distinct, i.e. some languages can lead to the same color. As default, 'grey' is returned.
	 */
	public static getColorForLanguage(language: ELanguage): SemanticCOLORS {
		switch (language.name) {
			case ELanguage.JAVASCRIPT.name:
			case ELanguage.PYTHON.name:
				return 'yellow';
			case ELanguage.CS.name:
				return 'purple';
			case ELanguage.JAVA.name:
			case ELanguage.RUST.name:
			case ELanguage.SWIFT.name:
				return 'red';
			case ELanguage.CPP.name:
			case ELanguage.ABAP.name:
			case ELanguage.GROOVY.name:
			case ELanguage.DELPHI.name:
				return 'blue';
			case ELanguage.GO.name:
				return 'teal';
			case ELanguage.KOTLIN.name:
			case ELanguage.SIMULINK.name:
			case ELanguage.MATLAB.name:
				return 'orange';
			default:
				return 'grey';
		}
	}

	/**
	 * Returns a SemanticUI color name for the given enablement, which can e.g. be used for colored enablement labels.
	 * As default, 'grey' is returned.
	 */
	public static getColorForFindingEnablement(findingEnablement: EFindingEnablementEntry): SemanticCOLORS {
		switch (findingEnablement) {
			case EFindingEnablement.YELLOW.name:
				return 'yellow';
			case EFindingEnablement.AUTO.name:
				return 'blue';
			case EFindingEnablement.RED.name:
				return 'red';
			default:
				return 'grey';
		}
	}
}
