import { assertIsHtmlElement } from 'ts-closure-library/lib/asserts/dom';
import * as soy from 'ts-closure-library/lib/soy/soy';
import { navigateTo, shouldBrowserHandle } from 'ts/base/routing/NavigateTo';
import type { SoyTemplate } from 'ts/base/soy/SoyTemplate';

/**
 * Anchor tags, that use this attribute ("data-do-not-use-react-router" in the HTML) are not routed by using our React
 * router. This is the case if some special handling should be performed when clicking the link, where the linked page
 * should not be loaded.
 */
const DO_NOT_USE_REACT_ROUTER_FLAG = 'doNotUseReactRouter';

/**
 * Renders a Soy template into a single node. If the rendered HTML string represents a single node, then that node is
 * returned. Otherwise, a DIV element is returned containing the rendered nodes.
 *
 * Always use this to render soy templates instead of plain soy.renderAsElement as this one makes embedded anchor
 * elements compatible with React Router.
 *
 * @param template The Soy template defining the element's content.
 * @param data The data for the template.
 * @returns {!Element} Rendered template contents, wrapped in a parent DIV element if necessary.
 */
export function renderAsElement<DATA>(template: SoyTemplate<DATA>, data?: DATA): HTMLElement {
	const element = soy.renderAsElement(template, data);
	hydrateLinksIn(element);
	return assertIsHtmlElement(element);
}

/**
 * Renders a Soy template and then set the output string as the innerHTML of an element. It is recommended to use this
 * helper function instead of directly setting innerHTML in your hand-written code, so that it will be easier to audit
 * the code for cross-site scripting vulnerabilities.
 *
 * Always use this to render soy templates instead of plain soy.renderAsElement as this one makes embedded anchor
 * elements compatible with React Router.
 *
 * @param element The element whose content we are rendering into.
 * @param template The Soy template defining the element's content.
 * @param data The data for the template.
 */
export function renderElement<DATA>(element: Element, template: SoyTemplate<DATA>, data?: DATA): void {
	soy.renderElement(element, template, data);
	hydrateLinksIn(element);
}

/**
 * Injects a custom onclick handler into all anchor elements (whose href attributes are set to something relevant) that
 * we want to delegate directly to the React router.
 */
export function hydrateLinksIn(element: Element): void {
	const anchors = element.querySelectorAll('a');
	for (const anchor of anchors) {
		hydrateLink(anchor);
	}
}

/**
 * Sets a custom click listener on the given anchor element, which will delegate a link click to our router. The
 * href-attribute of the link will be evaluated at the time of the click. This method assumes that empty links (e.g. for
 * '_blank') aren't altered later to point to something different, as such links won't be handled by our custom click
 * listener.
 */
function hydrateLink(anchor: HTMLAnchorElement): void {
	const target = anchor.target;
	if (target && target !== '_self') {
		// Let browser handle "target=_blank" etc.
		return;
	}
	const href = anchor.href;
	const plainHref = anchor.attributes.getNamedItem('href')?.value;
	if (
		plainHref === '#' ||
		plainHref === '' ||
		shouldBrowserHandle(href) ||
		anchor.dataset[DO_NOT_USE_REACT_ROUTER_FLAG]
	) {
		return;
	}

	anchor.onclick = event => {
		if (isRelevantEvent(event)) {
			event.preventDefault();
			navigateTo(anchor.href);
		}
	};
}

/** Returns whether this is a relevant event that should be handled by the react router. */
export function isRelevantEvent(event: MouseEvent): boolean {
	return (
		!event.defaultPrevented && // OnClick prevented default
		event.button === 0 && // Ignore everything but left clicks
		!isModifiedEvent(event) // Ignore clicks with modifier keys
	);
}

/** Whether any modifier key was pressed when the click occurred. */
function isModifiedEvent(event: MouseEvent): boolean {
	return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
}
