import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import { assert, assertString } from 'ts-closure-library/lib/asserts/asserts';
import {
	assertIsElement,
	assertIsHtmlButtonElement,
	assertIsHtmlElement,
	assertIsHtmlElementOfType,
	assertIsHtmlInputElement,
	assertIsHtmlTextAreaElement
} from 'ts-closure-library/lib/asserts/dom';
import * as googDom from 'ts-closure-library/lib/dom/dom';
import { replaceNode } from 'ts-closure-library/lib/dom/dom';
import { isHtmlElementOfType, isHtmlInputElement, isHtmlTextAreaElement } from 'ts-closure-library/lib/dom/element';
import * as forms from 'ts-closure-library/lib/dom/forms';
import { TagName } from 'ts-closure-library/lib/dom/tagname';
import { Size } from 'ts-closure-library/lib/math/size';
import * as strings from 'ts-closure-library/lib/string/string';
import * as style from 'ts-closure-library/lib/style/style';
import { Textarea } from 'ts-closure-library/lib/ui/textarea';
import { StringUtils } from './StringUtils';

/** Utility methods for DOM manipulation. */
export class tsdom {
	/**
	 * Replacement for dom.getElement, that also ensures that the resulting element exists (is not null).
	 *
	 * @param id Element ID or a DOM node.
	 * @returns The element with the given ID.
	 */
	public static getElementById(id: string): HTMLElement {
		const element = document.getElementById(id);
		if (element == null) {
			asserts.fail(`Element with ID ${id} does not exist!`);
		}
		return assertIsElement(element);
	}

	/**
	 * Replacement for dom.getElement, that also ensures that the resulting element exists (is not null) and is of type
	 * HTMLInputElement.
	 *
	 * @param id Element ID or a DOM node.
	 * @returns The element with the given ID.
	 */
	public static getHtmlInputElementById(id: string): HTMLInputElement {
		return assertIsHtmlInputElement(tsdom.getElementById(id));
	}

	/**
	 * Replacement for dom.getElement, that also ensures that the resulting element exists (is not null) and is of type
	 * HTMLTextAreaElement.
	 *
	 * @param id Element ID or a DOM node.
	 * @returns The element with the given ID.
	 */
	public static getHtmlTextAreaElementById(id: string): HTMLTextAreaElement {
		return assertIsHtmlTextAreaElement(tsdom.getElementById(id));
	}

	/**
	 * Replacement for dom.getElement, that also ensures that the resulting element exists (is not null) and is of type
	 * HTMLButtonElement.
	 *
	 * @param id Element ID or a DOM node.
	 * @returns The element with the given ID.
	 */
	public static getHtmlButtonElementById(id: string): HTMLButtonElement {
		return assertIsHtmlButtonElement(tsdom.getElementById(id));
	}

	/**
	 * Replacement for dom.getElement, that also ensures that the resulting element exists (is not null) and is of type
	 * HTMLSelectElement.
	 *
	 * @param id Element ID or a DOM node.
	 * @returns The element with the given ID.
	 */
	public static getHtmlSelectElementById(id: string): HTMLSelectElement {
		return tsdom.assertIsHTMLSelectElement(tsdom.getElementById(id));
	}

	/**
	 * Utility method for get the first child element below an explicit parent element with a given id. Requires the
	 * element to exist. Only use this method if the parent element is not yet contained in the rendered DOM tree.
	 * Otherwise use the much faster getElementById() method.
	 *
	 * @param id The element id.
	 * @param parentElement The parent element to search below.
	 * @returns The element with the given ID.
	 */
	public static getElementByIdWithParent(id: string, parentElement: Element): Element {
		return assertIsElement(tsdom.findElementByIdWithParent(id, parentElement));
	}

	/**
	 * Utility method for finding the first child element below an explicit parent element with a given id. Returns null
	 * if no element could be found. Only use this method if the parent element is not yet contained in the rendered DOM
	 * tree. Otherwise use the much faster getElementById() method.
	 *
	 * @param id The element id.
	 * @param parentElement The parent element to search below.
	 */
	public static findElementByIdWithParent(id: string, parentElement: Element): Element | null {
		return parentElement.querySelector('#' + id);
	}

	/** Replacement for goog_dom.getParentElement, that also ensures that the resulting element exists (is not null). */
	public static getParentElement(element: Element | null): Element {
		return assertIsElement(element?.parentElement);
	}

	/** Replacement for dom.getElementByClass, that also ensures that the resulting element exists (is not null). */
	public static getElementByClass(className: string, parentElement?: Element | Document | null): HTMLElement {
		const parent = parentElement ?? document;
		return assertIsHtmlElement(parent.getElementsByClassName(className)[0]!);
	}

	/** Replacement for dom.getElementByClass, that also ensures that the resulting element exists (is not null). */
	public static getHtmlInputElementByClass(
		className: string,
		parentElement?: Element | Document | null
	): HTMLInputElement {
		return assertIsHtmlInputElement(tsdom.getElementByClass(className, parentElement));
	}

	/** Replacement for dom.getElementByClass. */
	public static findElementByClass(className: string, parentElement?: Element | Document | null): HTMLElement | null {
		const parent = parentElement ?? document;
		const elementsByClassName = parent.getElementsByClassName(className);
		if (elementsByClassName.length > 0) {
			return assertIsHtmlElement(elementsByClassName[0]);
		} else {
			return null;
		}
	}

	/** Replacement for dom.getElementsByClass, that also ensures that the resulting element exists (is not null). */
	public static getElementsByClass(className: string, parentElement?: Element | Document | null): HTMLElement[] {
		const parent = parentElement ?? document;
		const elements = parent.querySelectorAll('.' + className);
		elements.forEach(element => assertIsHtmlElement(element));
		return Array.from(elements as NodeListOf<HTMLElement>);
	}

	/**
	 * Replacement for dom.getElementsByClass, that also ensures that the resulting element exists (is not null) and is
	 * an input element.
	 */
	public static getInputElementsByClass(
		className: string,
		parentElement?: Element | Document | null
	): HTMLInputElement[] {
		const elements = tsdom.getElementsByClass(className, parentElement);
		elements.forEach(element => assertIsHtmlInputElement(element));
		return elements as HTMLInputElement[];
	}

	/**
	 * Replacement for dom.getElementByTagNameAndClass, that also ensures that the resulting element exists (is not
	 * null).
	 *
	 * @param tagName Element tag name.
	 * @param className Optional class name.
	 * @param element Optional element to look in.
	 * @returns Reference to a DOM node.
	 */
	public static getElementByTagNameAndClass<K extends keyof HTMLElementTagNameMap>(
		tagName: K,
		className?: string,
		element: Document | Element = document
	): HTMLElementTagNameMap[K] {
		let selector: string = tagName;
		if (className) {
			selector += '.' + className;
		}
		return assertIsElement(element.querySelector(selector));
	}

	/**
	 * Replacement for dom.getElementsByTagNameAndClass. When you only want to look for a tag use querySelectorAll
	 * directly.
	 *
	 * @param tagName Element tag name.
	 * @param className Optional class name.
	 * @param element Optional element to look in.
	 * @returns Reference list to DOM nodes.
	 */
	public static getElementsByTagNameAndClass<K extends keyof HTMLElementTagNameMap>(
		tagName: K,
		className?: string,
		element: Document | Element = document
	): Array<HTMLElementTagNameMap[K]> {
		let selector: string = tagName;
		if (className) {
			selector += '.' + className;
		}
		return Array.from(element.querySelectorAll(selector));
	}

	/** Replacement for dom.getAncestorByClass, that also ensures that the resulting element exists (is not null). */
	public static getAncestorByClass(element: Node | null, className: string): HTMLElement {
		return assertIsHtmlElement(googDom.getAncestorByClass(element, className));
	}

	/** Replacement for goog_dom.getFirstElementChild, that also ensures that the resulting element exists (is not null). */
	public static getFirstElementChild(node: Node | null): HTMLElement {
		return assertIsHtmlElement(googDom.getFirstElementChild(node));
	}

	/** Replacement for goog_dom.getLastElementChild, that also ensures that the resulting element exists (is not null). */
	public static getLastElementChild(node: Node | null): HTMLElement {
		return assertIsHtmlElement(googDom.getLastElementChild(node));
	}

	/**
	 * Determines if the input element is checked.
	 *
	 * @returns True if checked.
	 */
	public static isChecked(element: Element): boolean {
		return assertIsHtmlInputElement(element).checked;
	}

	/** Returns the string value of a HTML input element. */
	public static getValue(element: Element): string {
		assert(
			isHtmlInputElement(element) ||
				isHtmlTextAreaElement(element) ||
				isHtmlElementOfType(element, TagName.SELECT),
			'Expected INPUT, TEXTAREA or SELECT element, but got ' + element.tagName
		);
		return assertString((element as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement).value);
	}

	/** Asserts that the element is an option element and returns it as such. */
	public static assertIsHTMLOptionElement(element: Element | null): HTMLOptionElement {
		return assertIsHtmlElementOfType(element, TagName.OPTION);
	}

	/** Asserts that the element is an option element and returns it as such. */
	public static assertIsHTMLSelectElement(element: Element | null): HTMLSelectElement {
		return assertIsHtmlElementOfType(element, TagName.SELECT);
	}

	/** Sets the option value for an option element */
	public static setOptionValue(element: Element, value: string): void {
		tsdom.assertIsHTMLOptionElement(element).value = value;
	}

	/** Sets the text attribute of an option element. */
	public static setOptionText(element: Element, text: string): void {
		tsdom.assertIsHTMLOptionElement(element).text = text;
	}

	/**
	 * Disables the given element.
	 *
	 * @param element Element to disable.
	 * @param disable If true the elements gets disabled.
	 */
	public static disableElement(element: Element | null, disable: boolean): void {
		let disabled = '';
		if (disable) {
			disabled = 'disable';
		}
		googDom.setProperties(element, { disabled });
	}

	/**
	 * Retrieves the DOM-element with the given id and parent and returns its value using forms.getValue(). If no
	 * element with the given id is found,
	 *
	 * @code {null} is Returned.
	 */
	public static getValueOfElement(id: string): string | string[] | null {
		const element = document.getElementById(id);
		if (element === null) {
			return null;
		}
		return forms.getValue(element);
	}

	/**
	 * Shows or hides an element with the given class.
	 *
	 * @param className The css class name
	 * @param visible Whether to show or hide the element
	 * @param parentElement To (optional) element to search for the target element
	 */
	public static setElementByClassVisible(className: string, visible: boolean, parentElement?: Element | null): void {
		tsdom.setElementShown(tsdom.getElementByClass(className, parentElement), visible);
	}

	/**
	 * Get the value of a text box or null if the value is empty or the textbox doesn't exist.
	 *
	 * @param className The class name of the text box element.
	 * @param parentElement A parent element containing the element with the class name.
	 */
	public static getValueOfTextBoxByClass(className: string, parentElement: Element): string | null {
		const textBox = googDom.getElementByClass(className, parentElement);
		if (textBox != null) {
			const value = forms.getValue(textBox) as string;
			return StringUtils.getNullIfEmpty(value);
		}
		return null;
	}

	/**
	 * Displays or hides a HTML block element. This method should be preferred over <code>style.setElementShown()</code>
	 * because block elements are correctly shown when the display CSS property is set to block instead of an empty
	 * string.
	 *
	 * @param element A HTML block element.
	 * @param show True to show otherwise false
	 */
	public static setBlockElementShown(element: Element, show: boolean): void {
		let elementStyle = 'none';
		if (show) {
			elementStyle = 'block';
		}
		style.setStyle(element, 'display', elementStyle);
	}

	/**
	 * Sets the elements display to none if show is false and resets it to empty otherwise.
	 *
	 * @param element A HTML element.
	 * @param show True to show otherwise false
	 */
	public static setElementShown(element: Element, show: boolean): void {
		(element as HTMLElement).style.display = show ? '' : 'none';
	}

	/**
	 * Calls {@link style.setElementShown} but performs a null/undefined check on the target element before. Will do
	 * nothing if the element does not exist.
	 *
	 * @param element A HTML block element.
	 * @param show True to show otherwise false
	 */
	public static setElementShownNullSafe(element: Element | null, show: boolean): void {
		if (element != null) {
			tsdom.setElementShown(element, show);
		}
	}

	/**
	 * Removes all DOM children of the given element that machtes the given predicate. Does _not_ traverse the children
	 * recursively.
	 *
	 * @param element
	 * @param removePredicate Called with the child element and its index within the other children. If the predicate
	 *   returns {@code true} for a child, the child will be removed from the DOM.
	 */
	public static removeDirectChild(element: Element, removePredicate: (childElement: Element) => boolean): void {
		const childrenToRemove: Element[] = [];
		for (const child of element.children) {
			if (removePredicate(child)) {
				childrenToRemove.push(child);
			}
		}
		tsdom.removeNodes(childrenToRemove);
	}

	/** Removes all DOM children of the given element. */
	public static removeAllChildren(element: Element): void {
		element.textContent = '';
	}

	/** Returns the string value of the form filed matching the name. */
	public static getStringValueByName(form: HTMLFormElement, name: string): string {
		return assertString(forms.getValueByName(form, name));
	}

	/**
	 * Replaces the page element with the given id with the given new element. Will do nothing if the placeholder
	 * element was not found on the page.
	 */
	public static replaceElementById(oldElementId: string, newElement: Element): void {
		const elementToReplace = document.getElementById(oldElementId);
		if (elementToReplace !== null) {
			replaceNode(newElement, elementToReplace);
		}
	}

	/**
	 * Removes the given node (element) from the DOM. If the Node is an element it should be removed with
	 * Element.remove() instead.
	 */
	public static removeNode(node: Node | null): void {
		node?.parentElement?.removeChild(node);
	}

	/** Removes the given nodes (elements) from the DOM. */
	public static removeNodes(nodes: Node[] | NodeListOf<Node>): void {
		nodes.forEach((node: Node) => tsdom.removeNode(node));
	}

	/**
	 * Checks if the given parent element has the given (potential) ancestor (e.g. as "grandchild") by recursively
	 * traversing the parents of <code>potentialAncestor</code>
	 */
	public static hasAncestor(parent: Element, potentialAncestor: Element): boolean {
		let currentParent = googDom.getParentElement(potentialAncestor);
		while (currentParent !== null) {
			if (currentParent === parent) {
				return true;
			}
			currentParent = googDom.getParentElement(currentParent);
		}
		return false;
	}

	/**
	 * <p>Tries to determine the width/height of the given element by checking <ul> <li>its value in the DOM (works if
	 * the element was already added to the DOM.</li> <li>any width/height pixel value set using an inline style
	 * (unsafe, but also works if the element has not been attached to the DOM yet. </li> </ul></p>
	 *
	 * @returns The width and height, or (0, 0)
	 */
	public static getSizeFromDomOrStyle(element: HTMLElement): Size {
		const sizeInDom = style.getSize(element);
		if (sizeInDom.width > 0 || sizeInDom.height > 0) {
			return sizeInDom;
		}
		return new Size(tsdom.getPixelSizeOrZero(element.style.width), tsdom.getPixelSizeOrZero(element.style.height));
	}

	/**
	 * Extracts the numeric pixel value of the given raw width/height value (e.g. 32 from '32px'). Will return 0 in case
	 * the value is not defined or does not use pixel units.
	 */
	private static getPixelSizeOrZero(attributeValue: string | null | number): number {
		if (attributeValue == null || !attributeValue.toString().endsWith('px')) {
			return 0;
		}
		return parseInt(strings.replaceAll(attributeValue.toString(), 'px', ''));
	}

	/** If an element with the given id is found on the page, it is removed. Otherwise, nothing is done. */
	public static removeMaybeExistingElement(elementId: string): void {
		const element = document.getElementById(elementId);
		if (element !== null) {
			tsdom.removeNode(element);
		}
	}

	/** Scrolls to the given element iff it is scrolled outside the current viewport. */
	public static scrollToElementIfNotInViewport(element: Element): void {
		const scrollMarkerValue = tsdom.elementIsInVerticalViewport(element);
		if (scrollMarkerValue != null) {
			const scrollToTop = scrollMarkerValue < 0;
			element.scrollIntoView(scrollToTop);
		}
	}

	/**
	 * Checks if the element is fully visible or cut-off (vertically).
	 *
	 * @returns <ul><li><code>null</code> if the element is fully visible,</li> <li>-1 in case one needs to scroll
	 *   up,</li> <li>1 in case one needs to scroll down.</li></ul>
	 */
	private static elementIsInVerticalViewport(element: Element): number | null {
		const rect = element.getBoundingClientRect();
		const referenceHeight = window.innerHeight || document.documentElement.clientHeight;
		if (rect.top < 0) {
			return -1;
		}
		if (rect.bottom > referenceHeight) {
			return 1;
		}
		return null;
	}

	/**
	 * Replaces the given text area with a counterpart from the Closure library that automatically grows with its
	 * content. While the Id and the class(es) of the original element will be preserved, this is not the case for
	 * potential listeners of the passed text area.
	 *
	 * @returns The new text area element
	 */
	public static replaceWithAutoHeightTextArea(originalTextArea: HTMLTextAreaElement): HTMLTextAreaElement {
		const growingTextArea = new Textarea(originalTextArea.value);
		growingTextArea.renderBefore(originalTextArea);
		const element = assertIsHtmlTextAreaElement(growingTextArea.getElement());
		element.id = originalTextArea.id;
		element.classList.add(...Array.from(originalTextArea.classList));
		tsdom.removeNode(originalTextArea);
		return element;
	}
}
