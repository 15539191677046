import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import { navigateTo } from 'ts/base/routing/NavigateTo';
import type { CodeLineSelection } from 'ts/commons/code/CodeLineSelection';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { ProjectAndUniformPath } from './ProjectAndUniformPath';
import { TimeUtils } from './time/TimeUtils';
import type { TypedPointInTime } from './time/TypedPointInTime';

/**
 * Imposes a common scheme for the URL's navigation hash and enforces proper encoding.
 *
 * Each hash consists of the following parts: <ol> <li>"#" <li>The name of the view to be shown or "" if that
 * information is not needed <li>"/" <li>The name of the project to be shown or "" if the first project should be shown
 * or the perspective is not project specific <li>"?" <li>Any number of key-value arguments (key "=" value) to the view
 * and perspective, separated by "&" </ol>
 *
 * There are helper methods for special types of parameters, e.g. array parameters, which appear multiple times or
 * parameters that provide a uniform path.
 *
 * This class should also be used to change the current navigation hash. In order for a change to take effect,
 * #navigate() has to be called.
 *
 * WARNING: It is bad practice to store an object of this class in an instance variable of a view or perspective, since
 * the hash may change, but the object will not be updated and thus contain outdated state.
 */
export class NavigationHash {
	/** The name of the timestamp parameter, which is used by many views. */
	public static TIMESTAMP_PARAMETER = 't';

	/** The name of the ID parameter, which is used by many views to identify an object. */
	public static ID_PARAMETER = 'id';

	/** The name of the baseline parameter. */
	public static BASELINE_PARAMETER = 'baseline';

	/** The name of the code line selection parameter. */
	public static SELECTION_PARAMETER = 'selection';

	/** The name of the 'enforced-visible' metric parameter. */
	public static VISIBLE_METRIC_PARAMETER = 'visibleMetric';

	/** The name of the threshold profile parameter. */
	public static THRESHOLD_PROFILE_PARAMETER = 'profile';

	/** The name of the highlight metric parameter. */
	public static HIGHLIGHT_METRIC = 'highlightMetric';

	/** The name of the new tab parameter. */
	public static NEW_TAB_PARAMETER = 'newtab';

	/** Special state that signals that the location change should not cause the view to be re-created. */
	public static readonly DO_NOT_RELOAD_VIEW_STATE = 'do-not-reload-the-view-for-this-change';

	/**
	 * To be able to explicitly store an empty array as state in the navigation hash we add a key with the given suffix
	 * as boolean. This allows us to differentiate between an explicitly empty state and the unset state where we want
	 * to use the default value when the user first navigates to the screen.
	 */
	private static readonly EMPTY_SUFFIX = '-empty';

	/** The name of the view to show or <code>null</code> if no view name was specified. */
	public viewName: string | null = null;

	/** The uniform path to show. */
	public projectAndPath: ProjectAndUniformPath | null = null;

	/**
	 * The view/perspective key-value arguments. Since arguments may appear more than once in the URL, we cannot use an
	 * Object here.
	 */
	public arguments: string[][] = [];

	/**
	 * @param historyToken The value of the hash which should be parsed. If no value is given, an empty hash is
	 *   constructed.
	 */
	public constructor(historyToken?: string) {
		if (historyToken == null) {
			historyToken = '';
		}
		this.setValue(historyToken);
	}

	/**
	 * Returns the arguments part of the string representation of this hash, i.e. the part following and including the
	 * "?" separator.
	 *
	 * @returns The string representation of the arguments part of the hash.
	 */
	public getArgumentsString(): string {
		const searchParams = new URLSearchParams(this.arguments);
		return '?' + searchParams.toString();
	}

	/**
	 * Returns the value of the commit/timestamp parameter or <code>null</code>, if none was given.
	 *
	 * @returns The timestamp of the hash or <code>null</code>.
	 */
	public getCommit(): UnresolvedCommitDescriptor | null {
		return this.getCommitParameter(NavigationHash.TIMESTAMP_PARAMETER);
	}

	/** Returns the value of the specified commit parameter or <code>null</code>, if none was given. */
	public getCommitParameter(parameterName: string): UnresolvedCommitDescriptor | null {
		const value = this.getString(parameterName);
		if (value === null) {
			return null;
		}
		return UnresolvedCommitDescriptor.fromString(value);
	}

	/**
	 * Returns the value of the timestamp parameter or <code>null</code>, if no timestamp was given.
	 *
	 * @returns The timestamp of the hash or <code>null</code>.
	 */
	public getTimestamp(): number | null {
		const commit = this.getCommit();
		if (commit !== null) {
			return commit.getTimestamp();
		}
		return null;
	}

	/** Sets the value of the commit/timestamp parameter. */
	public setCommit(commit: UnresolvedCommitDescriptor | null): this {
		if (commit != null) {
			this.set(NavigationHash.TIMESTAMP_PARAMETER, commit);
		} else {
			this.remove(NavigationHash.TIMESTAMP_PARAMETER);
		}
		return this;
	}

	/** Returns the value of the baseline parameter. */
	public getBaseline(): TypedPointInTime | null {
		const baselineToken = this.getString(NavigationHash.BASELINE_PARAMETER);
		if (baselineToken == null) {
			return null;
		}
		return TimeUtils.fromUrlToken(baselineToken);
	}

	/**
	 * Sets the value of the baseline parameter. Doesn't work for system version points in time.
	 *
	 * @param baselinePointInTime The point in time for this baseline.
	 */
	public setBaseline(baselinePointInTime: TypedPointInTime): this {
		const baselineToken = TimeUtils.toUrlToken(baselinePointInTime);
		return this.set(NavigationHash.BASELINE_PARAMETER, baselineToken);
	}

	/** Sets the value of the code line selection parameter. */
	public setSelection(selection: CodeLineSelection): this {
		return this.set(NavigationHash.SELECTION_PARAMETER, selection.getUrlRepresentation());
	}

	/** Returns the value of the ID parameter or <code>null</code> if no ID was given. */
	public getId(): string | null {
		return this.getString(NavigationHash.ID_PARAMETER);
	}

	/** Sets the value of the ID parameter. */
	public setId(id: number | string): this {
		return this.set(NavigationHash.ID_PARAMETER, id);
	}

	/**
	 * Turns the hash into a string.
	 *
	 * If you only want to change the current browser hash independent of the base URL, please consider using
	 * #navigate() instead.
	 *
	 * @returns The string representation of the hash.
	 */
	public toString(): string {
		let hash = '#';
		if (this.viewName) {
			hash += encodeURIComponent(this.viewName);
		}
		hash += '/';
		hash += encodeURIComponent(this.projectAndPath?.getProject() ?? '');
		hash += '/';
		hash += encodeURIComponent(this.projectAndPath?.getPath() ?? '');
		return hash + this.getArgumentsString();
	}

	/**
	 * Sets the boolean value for the given key only if the value is true.
	 *
	 * @param key The key to set
	 * @param value The value to set if true
	 */
	public setBooleanValueIfTrue(key: string, value: boolean | null): this {
		if (value != null && value) {
			this.set(key, value);
		} else {
			this.remove(key);
		}
		return this;
	}

	/**
	 * Sets the value of this navigation hash from a string.
	 *
	 * @param hash The hash to set as the value of this navigation hash.
	 */
	public setValue(hash: string): this {
		if (hash.startsWith('#')) {
			hash = hash.substring(1);
		}
		const argumentsIndex = hash.indexOf('?');
		let argumentsString;
		let pathAndView;
		if (argumentsIndex === -1) {
			pathAndView = hash;
			argumentsString = '';
		} else {
			pathAndView = hash.substring(0, argumentsIndex);
			argumentsString = hash.substring(argumentsIndex + 1);
		}
		const pathAndViewParts = pathAndView.split('/');
		const undecodedViewName = pathAndViewParts.shift();
		if (undecodedViewName) {
			this.viewName = decodeURIComponent(undecodedViewName);
		}
		const projectAndPath = pathAndViewParts.join('/') || '';
		this.projectAndPath = ProjectAndUniformPath.parse(decodeURIComponent(projectAndPath));
		this.refreshArguments(argumentsString);
		return this;
	}

	/**
	 * Updates the current arguments based on the given string.
	 *
	 * @param argumentsString A string containing the 'parameter=value' pairs. The arguments are separated by '&'.
	 */
	public refreshArguments(argumentsString: string): this {
		this.arguments = [];
		if (argumentsString.length === 0) {
			return this;
		}
		const args = new URLSearchParams(argumentsString);
		args.forEach((value, key) => {
			this.arguments.push([key, value]);
		});
		return this;
	}

	/**
	 * Sets the value of this navigation hash as the browser's new hash.
	 *
	 * @param replace If this is true, the URL is replaced, i.e. the back button will not contain the current page. This
	 *   is useful for redirects. If this is false, which is the default, the back button will allow navigation to the
	 *   current page, which should be used for "normal" links.
	 */
	public navigate(replace = false): void {
		navigateTo(this.getUrlWithNewHash(), replace);
	}

	/**
	 * Get the browser's new hash with url.
	 *
	 * @returns Url with new hash
	 */
	public getUrlWithNewHash(): string {
		const currentPage = window.location.href;
		const hash = this.toString();
		return new URL(hash, currentPage).toString();
	}

	/**
	 * Navigates to the given perspective.
	 *
	 * @param perspective The TeamscalePerspective to navigate to.
	 * @param viewName The view to show.
	 */
	public navigateToPerspective(perspective: ETeamscalePerspective, viewName?: string): void {
		if (viewName !== undefined) {
			this.setViewName(viewName);
		}
		const currentPage = window.location.href;
		const urlString = new URL(perspective.page + this.toString(), currentPage);

		navigateTo(urlString.toString());
	}

	/**
	 * Navigates to the given perspective by opening a new tab.
	 *
	 * @param perspective The TeamscalePerspective to navigate to.
	 * @param viewName The view to show.
	 * @param focus Whether to focus the tab.
	 */
	public navigateToPerspectiveInNewTab(perspective: ETeamscalePerspective, viewName?: string, focus?: boolean) {
		if (viewName !== undefined) {
			this.setViewName(viewName);
		}
		this.set(NavigationHash.NEW_TAB_PARAMETER, true);
		const currentPage = window.location.href;
		const urlString = new URL(perspective.page + this.toString(), currentPage);
		const windowObjectReference = window.open(urlString, '_blank');
		if (focus) {
			windowObjectReference?.focus();
		}
	}

	/** Sets the url to the current hash state, but does not perform a reload. */
	public applyUrlWithoutReload(): void {
		navigateTo(this.getUrlWithNewHash(), true, NavigationHash.DO_NOT_RELOAD_VIEW_STATE);
	}

	/** Primes the page for reload. **Doesn't navigate** */
	public toggleReload(): void {
		if (this.getString('reload') === 'true') {
			this.remove('reload');
		} else {
			// Change the url in the address bar, so that reload() is guaranteed to
			// actually do something.
			this.set('reload', 'true');
		}
	}

	/** Force reloads the current page. */
	public reload(replace?: boolean): void {
		this.toggleReload();
		this.navigate(replace);
	}

	/** Returns the name of the path to show. */
	public getProjectAndPath(): ProjectAndUniformPath {
		// Cannot return null as this.projectAndPath is set from the constructor
		return asserts.assertObject(this.projectAndPath);
	}

	/** Returns the name of the project to show. */
	public getProject(): string {
		return this.getProjectAndPath().getProject();
	}

	/** Sets the name of the path to show. */
	public setProjectAndPath(path: ProjectAndUniformPath | null): this {
		this.projectAndPath = path;
		return this;
	}

	/** Returns the name of the view to show. */
	public getViewName(): string | null {
		return this.viewName;
	}

	/** Sets the name of the view to show. */
	public setViewName(viewName: string | null): this {
		this.viewName = viewName;
		return this;
	}

	/** Returns the value of the first argument with the given key or <code>null</code> if no such argument exists. */
	public getString(key: string): string | null {
		const firstEntry = this.arguments.find(entry => entry[0] === key);
		if (firstEntry === undefined) {
			return null;
		}
		return firstEntry[1]!;
	}

	/** Returns the value of the action. */
	public getAction(): string | null {
		return this.getString('action');
	}

	/** Sets the action value. */
	public setAction(value: string): this {
		return this.set('action', value);
	}

	/**
	 * Returns the value of the first argument with the given key as a number. If no such argument exists or the
	 * argument can not be parsed as a floating point number, null will be returned.
	 */
	public getNumber(key: string): number | null {
		const value = this.getString(key);
		if (value === null) {
			return null;
		}
		const number = parseFloat(value);
		if (isNaN(number)) {
			return null;
		}
		return number;
	}

	/**
	 * Returns the value of the first argument with the given key as a boolean. If no such argument exists or the
	 * argument can not be parsed as a boolean, false will be returned.
	 */
	public getBoolean(key: string, defaultValue?: boolean): boolean;
	public getBoolean(key: string, defaultValue: null): boolean | null;
	public getBoolean(key: string, defaultValue: boolean | null = false): boolean | null {
		const value = this.getString(key);
		if (value != null) {
			return value === 'true';
		}
		return defaultValue;
	}

	/** Returns the value of all arguments with the given key as an array. */
	public getArray(key: string): string[] | null;
	public getArray(key: string, defaultValue: string[]): string[];
	public getArray(key: string, defaultValue?: string[]): string[] | null {
		const matchingEntries = this.arguments.filter(entry => entry[0] === key);
		const value = matchingEntries.map(entry => entry[1]!);
		const isEmpty = this.getBoolean(key + NavigationHash.EMPTY_SUFFIX);
		if (value.length !== 0 || isEmpty) {
			return value;
		}
		return defaultValue ?? null;
	}

	/** Removes any arguments with the given key. */
	public remove(key: string): this {
		this.arguments = this.arguments.filter(entry => entry[0] !== key);
		return this;
	}

	/** Removes any existing arguments with the given key and adds a new one with the given value. */
	public set(key: string, value: string | number | boolean | UnresolvedCommitDescriptor): this {
		this.remove(key);
		this.add(key, value.toString());
		return this;
	}

	/** Removes any existing arguments with the given key and adds a new one with the given values. */
	public setArray<T extends string | number | boolean | UnresolvedCommitDescriptor>(
		key: string,
		values: T[] | null
	): this {
		this.remove(key);
		this.remove(key + NavigationHash.EMPTY_SUFFIX);
		if (values != null) {
			if (values.length === 0) {
				this.set(key + NavigationHash.EMPTY_SUFFIX, true);
			} else {
				for (const value of values) {
					this.add(key, value.toString());
				}
			}
		}
		return this;
	}

	/**
	 * Adds a new argument with the given key and value. If there already is an argument with the given key, it will be
	 * left alone. If there is already an entry with the same key/value combination no new entry will be created,
	 * avoiding double parameters with the same value.
	 */
	public add(key: string, value: string | number | boolean): this {
		if (!this.arguments.includes([key, value.toString()])) {
			this.arguments.push([key, value.toString()]);
		}
		return this;
	}

	/**
	 * Gets the name of the active branch in a URL request. The branch name is separated from the timestamp value.
	 *
	 * @returns The name of the current branch.
	 */
	public getBranchName(): string | null {
		return this.getCommit()?.getBranchName() ?? null;
	}

	/** @returns The name of the active threshold profile, or <code>null</code> if none is set. */
	public getThresholdProfile(): string | null {
		return this.getString(NavigationHash.THRESHOLD_PROFILE_PARAMETER);
	}

	/**
	 * Returns the name of a metric that should be visible regardless of the hidden metrics settings, or
	 * <code>null</code> if none is set.
	 */
	public getEnforcedVisibleMetric(): string | null {
		return this.getString(NavigationHash.VISIBLE_METRIC_PARAMETER);
	}

	/** @returns The navigation hash currently set in the browser window. */
	public static getCurrent(): NavigationHash {
		// We can't use location.hash directly in case of Firefox the hash is
		// already url-decoded. See also
		// http://stackoverflow.com/questions/1703552/encoding-of-window-location-hash
		const href = location.href;
		const hashIndex = href.indexOf('#');
		if (hashIndex >= 0) {
			return new NavigationHash(href.substring(hashIndex));
		}
		return new NavigationHash();
	}

	/**
	 * Returns the value of the timestamp parameter or null if no timestamp was given.
	 *
	 * @returns The value of the timestamp parameter
	 */
	public static getCurrentTimestamp(): number | null {
		return NavigationHash.getCurrent().getTimestamp();
	}

	/**
	 * Returns the value of the commit parameter or null if no commit was given.
	 *
	 * @returns The value of the timestamp/commit parameter
	 */
	public static getCurrentCommit(): UnresolvedCommitDescriptor | null {
		return NavigationHash.getCurrent().getCommit();
	}

	/**
	 * Returns the value of the commit parameter or the latest commit on the default branch if no commit was given.
	 *
	 * @returns The value of the timestamp/commit parameter
	 */
	public static getCurrentCommitOrLatestOnDefaultBranch(): UnresolvedCommitDescriptor {
		return NavigationHash.getCurrent().getCommit() || UnresolvedCommitDescriptor.createLatestOnDefaultBranch();
	}

	/** Returns the ID of the current project from the navigation hash. */
	public static getProject(): string {
		return NavigationHash.getCurrent().getProject();
	}
}
