// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/commons/UIUtilsTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.UIUtilsTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import {SanitizedCss as goog_soy_data_SanitizedCss} from 'ts-closure-library/lib/soy/data';
import {SanitizedHtml as goog_soy_data_SanitizedHtml_} from 'ts-closure-library/lib/soy/data';
import {SanitizedHtmlAttribute as goog_soy_data_SanitizedHtmlAttribute} from 'ts-closure-library/lib/soy/data';
import * as google from 'ts-closure-library/lib/google';
import * as goog_html from 'ts-closure-library/lib/html/safehtml';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './LinkTemplate.soy.generated.js';


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $alerts = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.alerts']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.alerts'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui error message sticky validation-errors" style="display: none;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.alerts ts/commons/UIUtilsTemplate.soy:23"' : '') + '><i class="exclamation circle icon"></i></div><div class="ui warning message validation-warnings" style="display: none;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.alerts ts/commons/UIUtilsTemplate.soy:26"' : '') + '><i class="exclamation triangle icon"></i></div><div class="ui success message sticky validation-infos" style="display: none;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.alerts ts/commons/UIUtilsTemplate.soy:29"' : '') + '><i class="check circle icon"></i></div>');
};
export { $alerts as alerts };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $alerts.soyTemplateName = 'ts.commons.UIUtilsTemplate.alerts';
}


/**
 * @param {?$loadingSpinnerFooter.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $loadingSpinnerFooter = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $loadingSpinnerFooter$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text, opt_data.id, opt_data.markerClass, opt_data.colCount);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} text
 * @param {null|string|undefined=} id
 * @param {null|string|undefined=} markerClass
 * @param {null|number|undefined=} colCount
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $loadingSpinnerFooter$ = function($$areYouAnInternalCaller, $ijData, text, id, markerClass, colCount) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.loadingSpinnerFooter']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.loadingSpinnerFooter']({text: text, id: id, markerClass: markerClass, colCount: colCount}, $ijData);
  }
  soy.assertParamType(text == null || typeof text === 'string', 'text', text, '@param', 'null|string|undefined');
  soy.assertParamType(id == null || typeof id === 'string', 'id', id, '@param', 'null|string|undefined');
  soy.assertParamType(markerClass == null || typeof markerClass === 'string', 'markerClass', markerClass, '@param', 'null|string|undefined');
  soy.assertParamType(colCount == null || typeof colCount === 'number', 'colCount', colCount, '@param', 'null|number|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<tfoot' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.loadingSpinnerFooter ts/commons/UIUtilsTemplate.soy:47"' : '') + '><tr' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + (markerClass ? ' class="' + soy.$$escapeHtmlAttribute(markerClass) + '"' : '') + '><td' + (colCount ? ' colspan="' + soy.$$escapeHtmlAttribute(colCount) + '"' : '') + '>' + $loadingSpinner$(soy.$$internalCallMarkerDoNotUse, $ijData, text) + '</td></tr></tfoot>');
};
export { $loadingSpinnerFooter as loadingSpinnerFooter };
export { $loadingSpinnerFooter$ as loadingSpinnerFooter$ };
/**
 * @typedef {{
 *  text?: (null|string|undefined),
 *  id?: (null|string|undefined),
 *  markerClass?: (null|string|undefined),
 *  colCount?: (null|number|undefined),
 * }}
 */
$loadingSpinnerFooter.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $loadingSpinnerFooter.soyTemplateName = 'ts.commons.UIUtilsTemplate.loadingSpinnerFooter';
}


/**
 * @param {?$loadingSpinner.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $loadingSpinner = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $loadingSpinner$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} text
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $loadingSpinner$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.loadingSpinner']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.loadingSpinner']({text: text}, $ijData);
  }
  soy.assertParamType(text == null || typeof text === 'string', 'text', text, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<!-- If not $text fixes https://jira.cqse.eu/browse/TS-19152. --><div class="ui active inline text centered loader"' + (!text ? ' style="width: 100px !important;"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.loadingSpinner ts/commons/UIUtilsTemplate.soy:64"' : '') + '>' + (text ? soy.$$escapeHtml(text) : '') + '</div>');
};
export { $loadingSpinner as loadingSpinner };
export { $loadingSpinner$ as loadingSpinner$ };
/**
 * @typedef {{
 *  text?: (null|string|undefined),
 * }}
 */
$loadingSpinner.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $loadingSpinner.soyTemplateName = 'ts.commons.UIUtilsTemplate.loadingSpinner';
}


/**
 * @param {?$actionButton.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $actionButton = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $actionButton$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.id, opt_data.className, opt_data.attributes, opt_data.style);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} id
 * @param {null|string|undefined=} className
 * @param {!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined=} attributes
 * @param {!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined=} style
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $actionButton$ = function($$areYouAnInternalCaller, $ijData, id, className, attributes, style) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.actionButton']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.actionButton']({id: id, className: className, attributes: attributes, style: style}, $ijData);
  }
  soy.assertParamType(id == null || typeof id === 'string', 'id', id, '@param', 'null|string|undefined');
  soy.assertParamType(className == null || typeof className === 'string', 'className', className, '@param', 'null|string|undefined');
  soy.assertParamType(attributes == null || goog_soy_data_SanitizedHtmlAttribute.isCompatibleWith(attributes), 'attributes', attributes, '@param', '!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined');
  soy.assertParamType(style == null || goog_soy_data_SanitizedCss.isCompatibleWith(style), 'style', style, '@param', '!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<a' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + ' class="ts-dropdown ' + (className ? soy.$$escapeHtmlAttribute(className) : '') + '"' + (attributes ? soy.$$whitespaceHtmlAttributes(soy.$$filterHtmlAttributes(attributes)) : '') + (style ? ' style="' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(style)) + '"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.actionButton ts/commons/UIUtilsTemplate.soy:82"' : '') + '><i class="ellipsis vertical link icon"></i></a>');
};
export { $actionButton as actionButton };
export { $actionButton$ as actionButton$ };
/**
 * @typedef {{
 *  id?: (null|string|undefined),
 *  className?: (null|string|undefined),
 *  attributes?: (!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined),
 *  style?: (!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined),
 * }}
 */
$actionButton.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $actionButton.soyTemplateName = 'ts.commons.UIUtilsTemplate.actionButton';
}


/**
 * @param {?$actionDropdown.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $actionDropdown = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $actionDropdown$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.id, opt_data.className, opt_data.attributes, opt_data.style);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} id
 * @param {null|string|undefined=} className
 * @param {!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined=} attributes
 * @param {!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined=} style
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $actionDropdown$ = function($$areYouAnInternalCaller, $ijData, id, className, attributes, style) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.actionDropdown']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.actionDropdown']({id: id, className: className, attributes: attributes, style: style}, $ijData);
  }
  soy.assertParamType(id == null || typeof id === 'string', 'id', id, '@param', 'null|string|undefined');
  soy.assertParamType(className == null || typeof className === 'string', 'className', className, '@param', 'null|string|undefined');
  soy.assertParamType(attributes == null || goog_soy_data_SanitizedHtmlAttribute.isCompatibleWith(attributes), 'attributes', attributes, '@param', '!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined');
  soy.assertParamType(style == null || goog_soy_data_SanitizedCss.isCompatibleWith(style), 'style', style, '@param', '!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + ' class="ts-dropdown ui dropdown ' + (className ? soy.$$escapeHtmlAttribute(className) : '') + '"' + (attributes ? soy.$$whitespaceHtmlAttributes(soy.$$filterHtmlAttributes(attributes)) : '') + (style ? ' style="' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(style)) + '"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.actionDropdown ts/commons/UIUtilsTemplate.soy:96"' : '') + '><i class="ellipsis vertical icon"></i></div>');
};
export { $actionDropdown as actionDropdown };
export { $actionDropdown$ as actionDropdown$ };
/**
 * @typedef {{
 *  id?: (null|string|undefined),
 *  className?: (null|string|undefined),
 *  attributes?: (!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined),
 *  style?: (!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined),
 * }}
 */
$actionDropdown.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $actionDropdown.soyTemplateName = 'ts.commons.UIUtilsTemplate.actionDropdown';
}


/**
 * @param {!$dropdownItem.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $dropdownItem = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dropdownItem$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.title, opt_data.icon, opt_data.shortcut, opt_data.id);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} title
 * @param {null|string|undefined=} icon
 * @param {null|string|undefined=} shortcut
 * @param {null|string|undefined=} id
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $dropdownItem$ = function($$areYouAnInternalCaller, $ijData, title, icon, shortcut, id) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.dropdownItem']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.dropdownItem']({title: title, icon: icon, shortcut: shortcut, id: id}, $ijData);
  }
  soy.assertParamType(typeof title === 'string', 'title', title, '@param', 'string');
  soy.assertParamType(icon == null || typeof icon === 'string', 'icon', icon, '@param', 'null|string|undefined');
  soy.assertParamType(shortcut == null || typeof shortcut === 'string', 'shortcut', shortcut, '@param', 'null|string|undefined');
  soy.assertParamType(id == null || typeof id === 'string', 'id', id, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + ' class="item"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.dropdownItem ts/commons/UIUtilsTemplate.soy:113"' : '') + '><i class="grey ' + soy.$$escapeHtmlAttribute(icon != null ? icon : '') + ' icon"></i>' + (shortcut ? '<span class="description">' + soy.$$escapeHtml(shortcut) + '</span>' : '') + soy.$$escapeHtml(title) + '</div>');
};
export { $dropdownItem as dropdownItem };
export { $dropdownItem$ as dropdownItem$ };
/**
 * @typedef {{
 *  title: string,
 *  icon?: (null|string|undefined),
 *  shortcut?: (null|string|undefined),
 *  id?: (null|string|undefined),
 * }}
 */
$dropdownItem.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dropdownItem.soyTemplateName = 'ts.commons.UIUtilsTemplate.dropdownItem';
}


/**
 * @param {!$dropdownAction.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $dropdownAction = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dropdownAction$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.id, opt_data.title, opt_data.isLink);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} id
 * @param {string} title
 * @param {boolean} isLink
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $dropdownAction$ = function($$areYouAnInternalCaller, $ijData, id, title, isLink) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.dropdownAction']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.dropdownAction']({id: id, title: title, isLink: isLink}, $ijData);
  }
  soy.assertParamType(typeof id === 'string', 'id', id, '@param', 'string');
  soy.assertParamType(typeof title === 'string', 'title', title, '@param', 'string');
  soy.assertParamType(typeof isLink === 'boolean', 'isLink', isLink, '@param', 'boolean');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(isLink ? '<a' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + ' class="item" href="#"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.dropdownAction ts/commons/UIUtilsTemplate.soy:131"' : '') + '>' + soy.$$escapeHtml(title) + '</a>' : '<div' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + ' class="item"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.dropdownAction ts/commons/UIUtilsTemplate.soy:133"' : '') + '>' + soy.$$escapeHtml(title) + '</div>');
};
export { $dropdownAction as dropdownAction };
export { $dropdownAction$ as dropdownAction$ };
/**
 * @typedef {{
 *  id: string,
 *  title: string,
 *  isLink: boolean,
 * }}
 */
$dropdownAction.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dropdownAction.soyTemplateName = 'ts.commons.UIUtilsTemplate.dropdownAction';
}


/**
 * @param {!$fileUpload.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $fileUpload = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $fileUpload$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.name, opt_data.title, opt_data.id, opt_data.className, opt_data.accept, opt_data.multiple, opt_data.placeholder);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} name
 * @param {string} title
 * @param {null|string|undefined=} id
 * @param {null|string|undefined=} className
 * @param {null|string|undefined=} accept
 * @param {boolean|null|undefined=} multiple
 * @param {null|string|undefined=} placeholder
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $fileUpload$ = function($$areYouAnInternalCaller, $ijData, name, title, id, className, accept, multiple, placeholder) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.fileUpload']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.fileUpload']({name: name, title: title, id: id, className: className, accept: accept, multiple: multiple, placeholder: placeholder}, $ijData);
  }
  soy.assertParamType(typeof name === 'string', 'name', name, '@param', 'string');
  soy.assertParamType(typeof title === 'string', 'title', title, '@param', 'string');
  soy.assertParamType(id == null || typeof id === 'string', 'id', id, '@param', 'null|string|undefined');
  soy.assertParamType(className == null || typeof className === 'string', 'className', className, '@param', 'null|string|undefined');
  soy.assertParamType(accept == null || typeof accept === 'string', 'accept', accept, '@param', 'null|string|undefined');
  soy.assertParamType(multiple == null || typeof multiple === 'boolean', 'multiple', multiple, '@param', 'boolean|null|undefined');
  soy.assertParamType(placeholder == null || typeof placeholder === 'string', 'placeholder', placeholder, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="input-append fileupload"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.fileUpload ts/commons/UIUtilsTemplate.soy:160"' : '') + '><div class="ui fluid action input"><input type="text" placeholder="' + (placeholder ? soy.$$escapeHtmlAttribute(placeholder) : '') + '" disabled="true" class="uneditable-input filename"><span class="ui button">' + soy.$$escapeHtml(title) + '<input type="file" id="' + soy.$$escapeHtmlAttribute(id != null ? id : 'fileInput') + '"' + (multiple ? ' multiple' : '') + ' style="width: 100%" name="' + soy.$$escapeHtmlAttribute(name) + '" class="fileinput ' + (className ? soy.$$escapeHtmlAttribute(className) : '') + ' "' + (accept ? ' accept="' + soy.$$escapeHtmlAttribute(accept) + '"' : '') + '></span></div></div>');
};
export { $fileUpload as fileUpload };
export { $fileUpload$ as fileUpload$ };
/**
 * @typedef {{
 *  name: string,
 *  title: string,
 *  id?: (null|string|undefined),
 *  className?: (null|string|undefined),
 *  accept?: (null|string|undefined),
 *  multiple?: (boolean|null|undefined),
 *  placeholder?: (null|string|undefined),
 * }}
 */
$fileUpload.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $fileUpload.soyTemplateName = 'ts.commons.UIUtilsTemplate.fileUpload';
}


/**
 * @param {!$absoluteAndRelativeDateInput.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $absoluteAndRelativeDateInput = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $absoluteAndRelativeDateInput$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.className, opt_data.inputClassName, opt_data.value, opt_data.name);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} className
 * @param {string} inputClassName
 * @param {string} value
 * @param {null|string|undefined=} name
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $absoluteAndRelativeDateInput$ = function($$areYouAnInternalCaller, $ijData, className, inputClassName, value, name) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput']({className: className, inputClassName: inputClassName, value: value, name: name}, $ijData);
  }
  soy.assertParamType(typeof className === 'string', 'className', className, '@param', 'string');
  soy.assertParamType(typeof inputClassName === 'string', 'inputClassName', inputClassName, '@param', 'string');
  soy.assertParamType(typeof value === 'string', 'value', value, '@param', 'string');
  soy.assertParamType(name == null || typeof name === 'string', 'name', name, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui left action input ' + soy.$$escapeHtmlAttribute(className) + ' ts-relative-absolute-date"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput ts/commons/UIUtilsTemplate.soy:183"' : '') + '><button class="ui icon button switch-button" type="button" title="Switch between entering a concrete date and a relative date by specifying a timespan."><i aria-hidden="true" class="exchange icon"></i></button><input name="' + soy.$$escapeHtmlAttribute(name) + '" type="hidden" list="relative" class="' + soy.$$escapeHtmlAttribute(inputClassName) + '" value="' + soy.$$escapeHtmlAttribute(value) + '"><input list="relative" class="relative-input" title="Enter a relative date "/><datalist id="relative"><option value="1 day ago"><option value="1 week ago"><option value="2 weeks ago"><option value="4 weeks ago"><option value="30 days ago"><option value="6 weeks ago"><option value="90 days ago"><option value="6 month ago"><option value="9 month ago"><option value="1 year ago"></datalist><input class="absolute-input" type="date"/></div>');
};
export { $absoluteAndRelativeDateInput as absoluteAndRelativeDateInput };
export { $absoluteAndRelativeDateInput$ as absoluteAndRelativeDateInput$ };
/**
 * @typedef {{
 *  className: string,
 *  inputClassName: string,
 *  value: string,
 *  name?: (null|string|undefined),
 * }}
 */
$absoluteAndRelativeDateInput.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $absoluteAndRelativeDateInput.soyTemplateName = 'ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput';
}


/**
 * @param {!$csrfTokenInput.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $csrfTokenInput = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $csrfTokenInput$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.csrfToken);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} csrfToken
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $csrfTokenInput$ = function($$areYouAnInternalCaller, $ijData, csrfToken) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.csrfTokenInput']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.csrfTokenInput']({csrfToken: csrfToken}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<input type="hidden" name="csrfToken" value="' + soy.$$escapeHtmlAttribute(csrfToken) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.csrfTokenInput ts/commons/UIUtilsTemplate.soy:214"' : '') + '>');
};
export { $csrfTokenInput as csrfTokenInput };
export { $csrfTokenInput$ as csrfTokenInput$ };
/**
 * @typedef {{
 *  csrfToken: ?,
 * }}
 */
$csrfTokenInput.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $csrfTokenInput.soyTemplateName = 'ts.commons.UIUtilsTemplate.csrfTokenInput';
}


/**
 * @param {!$centeredDiv.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $centeredDiv = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $centeredDiv$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.content, opt_data.useFullWidth, opt_data.fullWidth, opt_data.containerSegment);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string} content
 * @param {boolean|null|undefined=} useFullWidth
 * @param {null|string|undefined=} fullWidth
 * @param {boolean|null|undefined=} containerSegment
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $centeredDiv$ = function($$areYouAnInternalCaller, $ijData, content, useFullWidth, fullWidth, containerSegment) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.centeredDiv']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.centeredDiv']({content: content, useFullWidth: useFullWidth, fullWidth: fullWidth, containerSegment: containerSegment}, $ijData);
  }
  soy.assertParamType(goog_soy_data_SanitizedHtml_.isCompatibleWith(content), 'content', content, '@param', '!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string');
  soy.assertParamType(useFullWidth == null || typeof useFullWidth === 'boolean', 'useFullWidth', useFullWidth, '@param', 'boolean|null|undefined');
  soy.assertParamType(fullWidth == null || typeof fullWidth === 'string', 'fullWidth', fullWidth, '@param', 'null|string|undefined');
  soy.assertParamType(containerSegment == null || typeof containerSegment === 'boolean', 'containerSegment', containerSegment, '@param', 'boolean|null|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (!useFullWidth ? ' class="ui centered container ' + soy.$$escapeHtmlAttribute(containerSegment ? 'segment' : '') + '"' : '') + ' style="' + (!useFullWidth ? 'max-width: ' + (fullWidth ? soy.$$escapeHtmlAttribute(soy.$$filterCssValue(fullWidth)) : '900px') + '!important' : '') + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.centeredDiv ts/commons/UIUtilsTemplate.soy:227"' : '') + '>' + soy.$$escapeHtml(content) + '</div>');
};
export { $centeredDiv as centeredDiv };
export { $centeredDiv$ as centeredDiv$ };
/**
 * @typedef {{
 *  content: (!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string),
 *  useFullWidth?: (boolean|null|undefined),
 *  fullWidth?: (null|string|undefined),
 *  containerSegment?: (boolean|null|undefined),
 * }}
 */
$centeredDiv.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $centeredDiv.soyTemplateName = 'ts.commons.UIUtilsTemplate.centeredDiv';
}


/**
 * @param {!$singleOrMultipleEntriesDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $singleOrMultipleEntriesDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $singleOrMultipleEntriesDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.entries, opt_data.multiSelection, opt_data.textFilterEnabled);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} entries
 * @param {?} multiSelection
 * @param {?} textFilterEnabled
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $singleOrMultipleEntriesDialog$ = function($$areYouAnInternalCaller, $ijData, entries, multiSelection, textFilterEnabled) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog']({entries: entries, multiSelection: multiSelection, textFilterEnabled: textFilterEnabled}, $ijData);
  }
  let $output = (textFilterEnabled ? '<div class="ui small fluid input" style="padding-right: 15px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog ts/commons/UIUtilsTemplate.soy:246"' : '') + '><input class="entry-filter filterText" type="text" placeholder="type filter text..." style="width: 100%"></div><div class="ui divider"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog ts/commons/UIUtilsTemplate.soy:249"' : '') + '></div>' : '') + '<div class="ui form"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog ts/commons/UIUtilsTemplate.soy:251"' : '') + '><div class="grouped fields">';
  const entry38452List = entries;
  const entry38452ListLen = entry38452List.length;
  for (let entry38452Index = 0; entry38452Index < entry38452ListLen; entry38452Index++) {
    const entry38452Data = entry38452List[entry38452Index];
    $output += '<div class="field" id="entry-field-' + soy.$$escapeHtmlAttribute(entry38452Data.name) + '"' + (entry38452Data.description ? soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + entry38452Data.description)) : '') + '><div class="ui ' + (!multiSelection ? 'radio' : '') + ' checkbox"><input class="entry-marker" data-entry-checkbox="true" type="' + (multiSelection ? 'checkbox' : 'radio') + '" id="' + soy.$$escapeHtmlAttribute(entry38452Data.name) + '" name="' + (multiSelection ? soy.$$escapeHtmlAttribute(entry38452Data.name) : 'metric') + '"' + (entry38452Data.isSelected ? ' checked="checked"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(entry38452Data.name) + '" class="entry-marker-title">' + soy.$$escapeHtml(entry38452Data.name) + '</label></div></div>';
  }
  $output += '</div></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $singleOrMultipleEntriesDialog as singleOrMultipleEntriesDialog };
export { $singleOrMultipleEntriesDialog$ as singleOrMultipleEntriesDialog$ };
/**
 * @typedef {{
 *  entries: ?,
 *  multiSelection: ?,
 *  textFilterEnabled: ?,
 * }}
 */
$singleOrMultipleEntriesDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $singleOrMultipleEntriesDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog';
}


/**
 * @param {!$hierarchicGroupedEntriesDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $hierarchicGroupedEntriesDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $hierarchicGroupedEntriesDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.groups, opt_data.multiSelection);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} groups
 * @param {?} multiSelection
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $hierarchicGroupedEntriesDialog$ = function($$areYouAnInternalCaller, $ijData, groups, multiSelection) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog']({groups: groups, multiSelection: multiSelection}, $ijData);
  }
  let $output = '<ul class="unstyled"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog ts/commons/UIUtilsTemplate.soy:290"' : '') + '>';
  const group38545List = groups;
  const group38545ListLen = group38545List.length;
  for (let group38545Index = 0; group38545Index < group38545ListLen; group38545Index++) {
    const group38545Data = group38545List[group38545Index];
    $output += '<li class="group-marker"><div class="ui ' + (multiSelection ? 'checkbox' : 'radio') + '"><input class="group-input-marker" type="' + (multiSelection ? 'checkbox' : 'radio') + '" id="' + soy.$$escapeHtmlAttribute(group38545Data.name) + '" name="' + (multiSelection ? soy.$$escapeHtmlAttribute(group38545Data.name) : 'metric') + '"' + (group38545Data.isSelected ? ' checked="checked"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(group38545Data.name) + '"><abbr>' + soy.$$escapeHtml(group38545Data.name) + '</abbr></label></div><div style="padding-left:10px">' + $singleOrMultipleEntriesWithDisplayNameDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, group38545Data[/** @type {?} */ ('metrics')], multiSelection) + '</div></li>';
  }
  $output += '</ul>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $hierarchicGroupedEntriesDialog as hierarchicGroupedEntriesDialog };
export { $hierarchicGroupedEntriesDialog$ as hierarchicGroupedEntriesDialog$ };
/**
 * @typedef {{
 *  groups: ?,
 *  multiSelection: ?,
 * }}
 */
$hierarchicGroupedEntriesDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $hierarchicGroupedEntriesDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog';
}


/**
 * @param {!$singleOrMultipleEntriesWithDisplayNameDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $singleOrMultipleEntriesWithDisplayNameDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $singleOrMultipleEntriesWithDisplayNameDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.entries, opt_data.multiSelection);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} entries
 * @param {?} multiSelection
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $singleOrMultipleEntriesWithDisplayNameDialog$ = function($$areYouAnInternalCaller, $ijData, entries, multiSelection) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog']({entries: entries, multiSelection: multiSelection}, $ijData);
  }
  let $output = '<ul class="unstyled"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog ts/commons/UIUtilsTemplate.soy:324"' : '') + '>';
  const entry38633List = entries;
  const entry38633ListLen = entry38633List.length;
  for (let entry38633Index = 0; entry38633Index < entry38633ListLen; entry38633Index++) {
    const entry38633Data = entry38633List[entry38633Index];
    $output += '<li><div class="ui ' + (multiSelection ? 'checkbox' : 'radio') + '"><input class="entry-marker" type="' + (multiSelection ? 'checkbox' : 'radio') + '" id="' + soy.$$escapeHtmlAttribute(entry38633Data.displayName) + '" name="' + (multiSelection ? soy.$$escapeHtmlAttribute(entry38633Data.name) : 'metric') + '"' + (entry38633Data.isSelected ? ' checked="checked"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(entry38633Data.displayName) + '"><abbr' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + entry38633Data.description)) + '>' + soy.$$escapeHtml(entry38633Data.displayName) + '</abbr></label></div></li>';
  }
  $output += '</ul>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $singleOrMultipleEntriesWithDisplayNameDialog as singleOrMultipleEntriesWithDisplayNameDialog };
export { $singleOrMultipleEntriesWithDisplayNameDialog$ as singleOrMultipleEntriesWithDisplayNameDialog$ };
/**
 * @typedef {{
 *  entries: ?,
 *  multiSelection: ?,
 * }}
 */
$singleOrMultipleEntriesWithDisplayNameDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $singleOrMultipleEntriesWithDisplayNameDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog';
}


/**
 * @param {!$divWithIdWrapper.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $divWithIdWrapper = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $divWithIdWrapper$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.id, opt_data.content);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} id
 * @param {?} content
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $divWithIdWrapper$ = function($$areYouAnInternalCaller, $ijData, id, content) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithIdWrapper']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithIdWrapper']({id: id, content: content}, $ijData);
  }
  soy.assertParamType(typeof id === 'string', 'id', id, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="' + soy.$$escapeHtmlAttribute(id) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.divWithIdWrapper ts/commons/UIUtilsTemplate.soy:353"' : '') + '>' + soy.$$escapeHtml(content) + '</div>');
};
export { $divWithIdWrapper as divWithIdWrapper };
export { $divWithIdWrapper$ as divWithIdWrapper$ };
/**
 * @typedef {{
 *  id: string,
 *  content: ?,
 * }}
 */
$divWithIdWrapper.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $divWithIdWrapper.soyTemplateName = 'ts.commons.UIUtilsTemplate.divWithIdWrapper';
}


/**
 * @param {!$divWithClass.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $divWithClass = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $divWithClass$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.styleClass);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} styleClass
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $divWithClass$ = function($$areYouAnInternalCaller, $ijData, styleClass) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithClass']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithClass']({styleClass: styleClass}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="' + soy.$$escapeHtmlAttribute(styleClass) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.divWithClass ts/commons/UIUtilsTemplate.soy:366"' : '') + '></div>');
};
export { $divWithClass as divWithClass };
export { $divWithClass$ as divWithClass$ };
/**
 * @typedef {{
 *  styleClass: ?,
 * }}
 */
$divWithClass.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $divWithClass.soyTemplateName = 'ts.commons.UIUtilsTemplate.divWithClass';
}


/**
 * @param {?$reAnalysisIndicator.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $reAnalysisIndicator = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $reAnalysisIndicator$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.requiresNoReAnalysis);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?=} requiresNoReAnalysis
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $reAnalysisIndicator$ = function($$areYouAnInternalCaller, $ijData, requiresNoReAnalysis) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.reAnalysisIndicator']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.reAnalysisIndicator']({requiresNoReAnalysis: requiresNoReAnalysis}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Changing this parameter will ' + (requiresNoReAnalysis ? '*not* ' : '') + 'lead to a re-analysis of the project')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.reAnalysisIndicator ts/commons/UIUtilsTemplate.soy:380"' : '') + '><span class="reanalyze-indicator' + (requiresNoReAnalysis ? ' safe' : ' required') + '"></span></span>');
};
export { $reAnalysisIndicator as reAnalysisIndicator };
export { $reAnalysisIndicator$ as reAnalysisIndicator$ };
/**
 * @typedef {{
 *  requiresNoReAnalysis?: (?|undefined),
 * }}
 */
$reAnalysisIndicator.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $reAnalysisIndicator.soyTemplateName = 'ts.commons.UIUtilsTemplate.reAnalysisIndicator';
}


/**
 * @param {!$legacyEscapeText.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {string}
 */
const $legacyEscapeText = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $legacyEscapeText$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} text
 * @return {string}
 * @suppress {checkTypes}
 */
const $legacyEscapeText$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.legacyEscapeText']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.legacyEscapeText']({text: text}, $ijData);
  }
  soy.assertParamType(typeof text === 'string', 'text', text, '@param', 'string');
  return soy.$$escapeUri(text);
};
export { $legacyEscapeText as legacyEscapeText };
export { $legacyEscapeText$ as legacyEscapeText$ };
/**
 * @typedef {{
 *  text: string,
 * }}
 */
$legacyEscapeText.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $legacyEscapeText.soyTemplateName = 'ts.commons.UIUtilsTemplate.legacyEscapeText';
}


/**
 * @param {!$metricTableConfigDialogContent.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $metricTableConfigDialogContent = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.metricTableConfigDialogContent']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.metricTableConfigDialogContent'](opt_data, $ijData);
  }
  /** @type {?} */
  const entries = opt_data.entries;
  /** @type {?} */
  const multiSelection = opt_data.multiSelection;
  /** @type {?} */
  const textFilterEnabled = opt_data.textFilterEnabled;
  /** @type {?} */
  const thresholdProfileNames = opt_data.thresholdProfileNames;
  /** @type {?} */
  const useAbbreviatedNumbers = opt_data.useAbbreviatedNumbers;
  /** @type {?} */
  const limitMetricsToProfile = opt_data.limitMetricsToProfile;
  /** @type {?} */
  const selectedThresholdProfile = opt_data.selectedThresholdProfile;
  let $output = '<h5' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:419"' : '') + '>Number Formatting</h5><div class="ui checkbox"' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'If enabled, large numbers will be render with a suffix, e.g. 3.5k')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:420"' : '') + '><input type="checkbox" id="checkbox-abbreviate-numbers"' + (useAbbreviatedNumbers ? ' checked="true"' : '') + '><label for="checkbox-abbreviate-numbers" class="checkbox">Abbreviate large numbers in table,\ne.g. <i>3.5k</i></label></div><h5' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:428"' : '') + '>Threshold Profile  ' + $helpIcon$(soy.$$internalCallMarkerDoNotUse, $ijData, 'A threshold profile tells Teamscale how to assess metric values') + '</h5>';
  if (soy.$$coerceToBoolean(thresholdProfileNames) && thresholdProfileNames.length > 0) {
    $output += '<select id="threshold-select" class="ui dropdown"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:432"' : '') + '>';
    const profileName38826List = thresholdProfileNames;
    const profileName38826ListLen = profileName38826List.length;
    for (let profileName38826Index = 0; profileName38826Index < profileName38826ListLen; profileName38826Index++) {
      const profileName38826Data = profileName38826List[profileName38826Index];
      $output += '<option' + (soy.$$equals(profileName38826Data, selectedThresholdProfile) ? ' selected' : '') + '>' + soy.$$escapeHtml(profileName38826Data) + '</option>';
    }
    $output += '</select><div class="ui marginless basic segment"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:437"' : '') + '><div class="ui checkbox"' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'If enabled, only metrics contained in the selected threshold profile will be shown.')) + '><input type="checkbox" id="checkbox-limit-to-profile"' + (limitMetricsToProfile ? ' checked="true"' : '') + '><label for="checkbox-limit-to-profile" class="checkbox">Only show metrics from this profile</label></div></div>';
  }
  $output += '<h5' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:449"' : '') + '>Metrics to show</h5>' + $singleOrMultipleEntriesDialog(/** @type {?} */ (opt_data), $ijData);
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $metricTableConfigDialogContent as metricTableConfigDialogContent };
/**
 * @typedef {{
 *  entries: ?,
 *  multiSelection: ?,
 *  textFilterEnabled: ?,
 *  thresholdProfileNames: ?,
 *  useAbbreviatedNumbers?: (?|undefined),
 *  limitMetricsToProfile?: (?|undefined),
 *  selectedThresholdProfile?: (?|undefined),
 * }}
 */
$metricTableConfigDialogContent.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $metricTableConfigDialogContent.soyTemplateName = 'ts.commons.UIUtilsTemplate.metricTableConfigDialogContent';
}


/**
 * @param {!$helpIcon.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $helpIcon = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $helpIcon$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} text
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $helpIcon$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.helpIcon']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.helpIcon']({text: text}, $ijData);
  }
  soy.assertParamType(typeof text === 'string', 'text', text, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, text)) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.helpIcon ts/commons/UIUtilsTemplate.soy:457"' : '') + '><i class="grey help circle icon"></i></span>');
};
export { $helpIcon as helpIcon };
export { $helpIcon$ as helpIcon$ };
/**
 * @typedef {{
 *  text: string,
 * }}
 */
$helpIcon.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $helpIcon.soyTemplateName = 'ts.commons.UIUtilsTemplate.helpIcon';
}


/**
 * @param {!$autocompleteFilterCheckbox.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $autocompleteFilterCheckbox = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $autocompleteFilterCheckbox$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.data, opt_data.id, opt_data.disable);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} data
 * @param {string} id
 * @param {?=} disable
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $autocompleteFilterCheckbox$ = function($$areYouAnInternalCaller, $ijData, data, id, disable) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.autocompleteFilterCheckbox']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.autocompleteFilterCheckbox']({data: data, id: id, disable: disable}, $ijData);
  }
  soy.assertParamType(typeof id === 'string', 'id', id, '@param', 'string');
  let $output = '<div class="field"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.autocompleteFilterCheckbox ts/commons/UIUtilsTemplate.soy:474"' : '') + '><div class="ui checkbox">';
  const checkboxId__soy38923 = '' + id + data;
  $output += '<input id="' + soy.$$escapeHtmlAttribute(checkboxId__soy38923) + '" type="checkbox" class="' + soy.$$escapeHtmlAttribute(id) + '-checkbox" checked="checked" data-text="' + soy.$$escapeHtmlAttribute(data) + '"' + (disable ? ' disabled="disabled"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(checkboxId__soy38923) + '">' + soy.$$escapeHtml(data) + '</label></div></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $autocompleteFilterCheckbox as autocompleteFilterCheckbox };
export { $autocompleteFilterCheckbox$ as autocompleteFilterCheckbox$ };
/**
 * @typedef {{
 *  data: ?,
 *  id: string,
 *  disable?: (?|undefined),
 * }}
 */
$autocompleteFilterCheckbox.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $autocompleteFilterCheckbox.soyTemplateName = 'ts.commons.UIUtilsTemplate.autocompleteFilterCheckbox';
}


/**
 * @param {!$findingMessage.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $findingMessage = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingMessage$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.finding);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} finding
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $findingMessage$ = function($$areYouAnInternalCaller, $ijData, finding) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.findingMessage']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.findingMessage']({finding: finding}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml(finding.renderedMessage ? soy.$$escapeHtml(finding.renderedMessage) : soy.$$escapeHtml(finding.message));
};
export { $findingMessage as findingMessage };
export { $findingMessage$ as findingMessage$ };
/**
 * @typedef {{
 *  finding: ?,
 * }}
 */
$findingMessage.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingMessage.soyTemplateName = 'ts.commons.UIUtilsTemplate.findingMessage';
}


/**
 * @param {!$strike.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $strike = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $strike$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.content, opt_data.shouldStrike, opt_data.sortValue);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string} content
 * @param {boolean|null|undefined=} shouldStrike
 * @param {null|string|undefined=} sortValue
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $strike$ = function($$areYouAnInternalCaller, $ijData, content, shouldStrike, sortValue) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.strike']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.strike']({shouldStrike: shouldStrike, content: content, sortValue: sortValue}, $ijData);
  }
  soy.assertParamType(shouldStrike == null || typeof shouldStrike === 'boolean', 'shouldStrike', shouldStrike, '@param', 'boolean|null|undefined');
  soy.assertParamType(goog_soy_data_SanitizedHtml_.isCompatibleWith(content), 'content', content, '@param', '!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string');
  soy.assertParamType(sortValue == null || typeof sortValue === 'string', 'sortValue', sortValue, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(shouldStrike == null || shouldStrike ? '<strike' + (sortValue != null ? ' data-sort-value=' + soy.$$escapeHtmlAttributeNospace(sortValue) : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.strike ts/commons/UIUtilsTemplate.soy:502"' : '') + '>' + soy.$$escapeHtml(content) + '</strike>' : soy.$$escapeHtml(content));
};
export { $strike as strike };
export { $strike$ as strike$ };
/**
 * @typedef {{
 *  shouldStrike?: (boolean|null|undefined),
 *  content: (!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string),
 *  sortValue?: (null|string|undefined),
 * }}
 */
$strike.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $strike.soyTemplateName = 'ts.commons.UIUtilsTemplate.strike';
}


/**
 * @param {!$wrapInLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $wrapInLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $wrapInLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.content, opt_data.shouldWrap, opt_data.linkAttributes);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string} content
 * @param {boolean|null|undefined=} shouldWrap
 * @param {!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined=} linkAttributes
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $wrapInLink$ = function($$areYouAnInternalCaller, $ijData, content, shouldWrap, linkAttributes) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.wrapInLink']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.wrapInLink']({shouldWrap: shouldWrap, linkAttributes: linkAttributes, content: content}, $ijData);
  }
  soy.assertParamType(shouldWrap == null || typeof shouldWrap === 'boolean', 'shouldWrap', shouldWrap, '@param', 'boolean|null|undefined');
  soy.assertParamType(linkAttributes == null || goog_soy_data_SanitizedHtmlAttribute.isCompatibleWith(linkAttributes), 'linkAttributes', linkAttributes, '@param', '!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined');
  soy.assertParamType(goog_soy_data_SanitizedHtml_.isCompatibleWith(content), 'content', content, '@param', '!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(shouldWrap == null || shouldWrap ? '<a' + (linkAttributes != null ? soy.$$whitespaceHtmlAttributes(soy.$$filterHtmlAttributes(linkAttributes)) : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.wrapInLink ts/commons/UIUtilsTemplate.soy:518"' : '') + '>' + soy.$$escapeHtml(content) + '</a>' : soy.$$escapeHtml(content));
};
export { $wrapInLink as wrapInLink };
export { $wrapInLink$ as wrapInLink$ };
/**
 * @typedef {{
 *  shouldWrap?: (boolean|null|undefined),
 *  linkAttributes?: (!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined),
 *  content: (!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string),
 * }}
 */
$wrapInLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $wrapInLink.soyTemplateName = 'ts.commons.UIUtilsTemplate.wrapInLink';
}


/**
 * @param {!$noDataMessage.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $noDataMessage = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $noDataMessage$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.title, opt_data.message);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} title
 * @param {string} message
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $noDataMessage$ = function($$areYouAnInternalCaller, $ijData, title, message) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.noDataMessage']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.noDataMessage']({title: title, message: message}, $ijData);
  }
  soy.assertParamType(typeof title === 'string', 'title', title, '@param', 'string');
  soy.assertParamType(typeof message === 'string', 'message', message, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui message error"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.noDataMessage ts/commons/UIUtilsTemplate.soy:536"' : '') + '><div class="header"><i class="info circle icon"></i> ' + soy.$$escapeHtml(title) + '</div><p>' + soy.$$escapeHtml(message) + '</p></div>');
};
export { $noDataMessage as noDataMessage };
export { $noDataMessage$ as noDataMessage$ };
/**
 * @typedef {{
 *  title: string,
 *  message: string,
 * }}
 */
$noDataMessage.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $noDataMessage.soyTemplateName = 'ts.commons.UIUtilsTemplate.noDataMessage';
}


/**
 * @param {!$noMergeRequestMessage.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $noMergeRequestMessage = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $noMergeRequestMessage$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.title, opt_data.message, opt_data.linkMessage);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} title
 * @param {string} message
 * @param {string} linkMessage
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $noMergeRequestMessage$ = function($$areYouAnInternalCaller, $ijData, title, message, linkMessage) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.noMergeRequestMessage']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.noMergeRequestMessage']({title: title, message: message, linkMessage: linkMessage}, $ijData);
  }
  soy.assertParamType(typeof title === 'string', 'title', title, '@param', 'string');
  soy.assertParamType(typeof message === 'string', 'message', message, '@param', 'string');
  soy.assertParamType(typeof linkMessage === 'string', 'linkMessage', linkMessage, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui message error"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.noMergeRequestMessage ts/commons/UIUtilsTemplate.soy:553"' : '') + '><div class="header"><i class="info circle icon"></i> ' + soy.$$escapeHtml(title) + '</div><p>' + soy.$$escapeHtml(message) + '</p><p>' + soy.$$escapeHtml(linkMessage) + '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'delta'))) + '">here.</a></p></div>');
};
export { $noMergeRequestMessage as noMergeRequestMessage };
export { $noMergeRequestMessage$ as noMergeRequestMessage$ };
/**
 * @typedef {{
 *  title: string,
 *  message: string,
 *  linkMessage: string,
 * }}
 */
$noMergeRequestMessage.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $noMergeRequestMessage.soyTemplateName = 'ts.commons.UIUtilsTemplate.noMergeRequestMessage';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $perspectiveProgress = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.perspectiveProgress']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.perspectiveProgress'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="perspective-progress" class="ui top attached active progress"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.perspectiveProgress ts/commons/UIUtilsTemplate.soy:571"' : '') + '><div class="bar"></div></div>');
};
export { $perspectiveProgress as perspectiveProgress };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $perspectiveProgress.soyTemplateName = 'ts.commons.UIUtilsTemplate.perspectiveProgress';
}


/**
 * @param {!$previousNext.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $previousNext = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $previousNext$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.previousTooltip, opt_data.nextTooltip);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} previousTooltip
 * @param {string} nextTooltip
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $previousNext$ = function($$areYouAnInternalCaller, $ijData, previousTooltip, nextTooltip) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.previousNext']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.previousNext']({previousTooltip: previousTooltip, nextTooltip: nextTooltip}, $ijData);
  }
  soy.assertParamType(typeof previousTooltip === 'string', 'previousTooltip', previousTooltip, '@param', 'string');
  soy.assertParamType(typeof nextTooltip === 'string', 'nextTooltip', nextTooltip, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui right floated marginless basic segment"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.previousNext ts/commons/UIUtilsTemplate.soy:588"' : '') + '><button id="previous-arrow" class="mini icon ui button"' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, previousTooltip)) + ' disabled="disabled"><i class="chevron left icon"></i></button><button id="next-arrow" class="mini icon ui button"' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, nextTooltip)) + ' disabled="disabled"><i class="chevron right icon"></i></button></div>');
};
export { $previousNext as previousNext };
export { $previousNext$ as previousNext$ };
/**
 * @typedef {{
 *  previousTooltip: string,
 *  nextTooltip: string,
 * }}
 */
$previousNext.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $previousNext.soyTemplateName = 'ts.commons.UIUtilsTemplate.previousNext';
}


/**
 * @param {!$tabContainer.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $tabContainer = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $tabContainer$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.tabNames, opt_data.tabMenuClassPrefix, opt_data.indexOfActiveTab, opt_data.tabAnnotations, opt_data.dontAddTabContentPanes);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<string>} tabNames
 * @param {string} tabMenuClassPrefix
 * @param {null|number|undefined=} indexOfActiveTab
 * @param {!Array<null|number|string|undefined>|null|undefined=} tabAnnotations
 * @param {boolean|null|undefined=} dontAddTabContentPanes
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $tabContainer$ = function($$areYouAnInternalCaller, $ijData, tabNames, tabMenuClassPrefix, indexOfActiveTab, tabAnnotations, dontAddTabContentPanes) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.tabContainer']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.tabContainer']({tabNames: tabNames, tabMenuClassPrefix: tabMenuClassPrefix, indexOfActiveTab: indexOfActiveTab, tabAnnotations: tabAnnotations, dontAddTabContentPanes: dontAddTabContentPanes}, $ijData);
  }
  soy.assertParamType(Array.isArray(tabNames), 'tabNames', tabNames, '@param', '!Array<string>');
  soy.assertParamType(typeof tabMenuClassPrefix === 'string', 'tabMenuClassPrefix', tabMenuClassPrefix, '@param', 'string');
  soy.assertParamType(indexOfActiveTab == null || typeof indexOfActiveTab === 'number', 'indexOfActiveTab', indexOfActiveTab, '@param', 'null|number|undefined');
  soy.assertParamType(tabAnnotations == null || Array.isArray(tabAnnotations), 'tabAnnotations', tabAnnotations, '@param', '!Array<null|number|string|undefined>|null|undefined');
  soy.assertParamType(dontAddTabContentPanes == null || typeof dontAddTabContentPanes === 'boolean', 'dontAddTabContentPanes', dontAddTabContentPanes, '@param', 'boolean|null|undefined');
  let $output = '<div class="ui pointing secondary menu ' + soy.$$escapeHtmlAttribute(tabMenuClassPrefix) + '-tab-menu"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.tabContainer ts/commons/UIUtilsTemplate.soy:618"' : '') + '>';
  const tabName39176List = tabNames;
  const tabName39176ListLen = tabName39176List.length;
  for (let tabName39176Index = 0; tabName39176Index < tabName39176ListLen; tabName39176Index++) {
    const tabName39176Data = tabName39176List[tabName39176Index];
    $output += '<div id="tab-item-' + soy.$$escapeHtmlAttribute(tabName39176Data) + '" class="tab item ' + (tabName39176Index == (indexOfActiveTab != null ? indexOfActiveTab : 0) ? ' active' : '') + '" style="' + (soy.$$coerceToBoolean(tabAnnotations) && !tabAnnotations[/** @type {?} */ (tabName39176Index)] ? ' padding-bottom: 1.1em' : '') + '" data-index="' + soy.$$escapeHtmlAttribute(tabName39176Index) + '" data-tab="tab-' + soy.$$escapeHtmlAttribute(tabName39176Data) + '">' + soy.$$escapeHtml(tabName39176Data) + (soy.$$coerceToBoolean(tabAnnotations) && soy.$$coerceToBoolean(tabAnnotations[/** @type {?} */ (tabName39176Index)]) ? $circularLabel$(soy.$$internalCallMarkerDoNotUse, $ijData, tabAnnotations[/** @type {?} */ (tabName39176Index)], true) : '') + '</div>';
  }
  $output += '<div class="invisible right item"></div></div>';
  if (!dontAddTabContentPanes) {
    const tabName39230List = tabNames;
    const tabName39230ListLen = tabName39230List.length;
    for (let tabName39230Index = 0; tabName39230Index < tabName39230ListLen; tabName39230Index++) {
      const tabName39230Data = tabName39230List[tabName39230Index];
      $output += '<div class="ui bottom attached tab tab-content ' + (tabName39230Index == (indexOfActiveTab != null ? indexOfActiveTab : 0) ? ' active' : '') + '" data-index="' + soy.$$escapeHtmlAttribute(tabName39230Index) + '" data-tab="tab-' + soy.$$escapeHtmlAttribute(tabName39230Data) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.tabContainer ts/commons/UIUtilsTemplate.soy:645"' : '') + '></div>';
    }
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $tabContainer as tabContainer };
export { $tabContainer$ as tabContainer$ };
/**
 * @typedef {{
 *  tabNames: !Array<string>,
 *  tabMenuClassPrefix: string,
 *  indexOfActiveTab?: (null|number|undefined),
 *  tabAnnotations?: (!Array<null|number|string|undefined>|null|undefined),
 *  dontAddTabContentPanes?: (boolean|null|undefined),
 * }}
 */
$tabContainer.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $tabContainer.soyTemplateName = 'ts.commons.UIUtilsTemplate.tabContainer';
}


/**
 * @param {!$circularLabel.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $circularLabel = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $circularLabel$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text, opt_data.useSmallLabel);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {number|string} text
 * @param {boolean|null|undefined=} useSmallLabel
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $circularLabel$ = function($$areYouAnInternalCaller, $ijData, text, useSmallLabel) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.circularLabel']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.circularLabel']({text: text, useSmallLabel: useSmallLabel}, $ijData);
  }
  soy.assertParamType(typeof text === 'number' || typeof text === 'string', 'text', text, '@param', 'number|string');
  soy.assertParamType(useSmallLabel == null || typeof useSmallLabel === 'boolean', 'useSmallLabel', useSmallLabel, '@param', 'boolean|null|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span class="ui circular ' + (useSmallLabel ? 'small' : '') + ' light label" style="vertical-align: middle"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.circularLabel ts/commons/UIUtilsTemplate.soy:657"' : '') + '>' + soy.$$escapeHtml(text) + '</span>');
};
export { $circularLabel as circularLabel };
export { $circularLabel$ as circularLabel$ };
/**
 * @typedef {{
 *  text: (number|string),
 *  useSmallLabel?: (boolean|null|undefined),
 * }}
 */
$circularLabel.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $circularLabel.soyTemplateName = 'ts.commons.UIUtilsTemplate.circularLabel';
}


/**
 * @param {!$tooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtmlAttribute}
 */
const $tooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} text
 * @return {!goog_soy_data_SanitizedHtmlAttribute}
 * @suppress {checkTypes}
 */
const $tooltip$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.tooltip']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.tooltip']({text: text}, $ijData);
  }
  soy.assertParamType(typeof text === 'string', 'text', text, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtmlAttribute(text ? 'title="' + soy.$$escapeHtmlAttribute(text) + '"' : '');
};
export { $tooltip as tooltip };
export { $tooltip$ as tooltip$ };
/**
 * @typedef {{
 *  text: string,
 * }}
 */
$tooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $tooltip.soyTemplateName = 'ts.commons.UIUtilsTemplate.tooltip';
}


/**
 * @param {!$baselineEndDateInformation.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $baselineEndDateInformation = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $baselineEndDateInformation$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.info);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {{baselineShortText: string, endShortText: string, baselineDescription: string, endDescription: string,}} info
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $baselineEndDateInformation$ = function($$areYouAnInternalCaller, $ijData, info) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.baselineEndDateInformation']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.baselineEndDateInformation']({info: info}, $ijData);
  }
  soy.assertParamType(google.isObject(info), 'info', info, '@param', '{baselineShortText: string, endShortText: string, baselineDescription: string, endDescription: string,}');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<abbr title="' + soy.$$escapeHtmlAttribute(info.baselineDescription) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.baselineEndDateInformation ts/commons/UIUtilsTemplate.soy:680"' : '') + '>' + soy.$$escapeHtml(info.baselineShortText) + '</abbr> \u2013 <abbr title="' + soy.$$escapeHtmlAttribute(info.endDescription) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.baselineEndDateInformation ts/commons/UIUtilsTemplate.soy:680"' : '') + '>' + soy.$$escapeHtml(info.endShortText) + '</abbr>');
};
export { $baselineEndDateInformation as baselineEndDateInformation };
export { $baselineEndDateInformation$ as baselineEndDateInformation$ };
/**
 * @typedef {{
 *  info: {baselineShortText: string, endShortText: string, baselineDescription: string, endDescription: string,},
 * }}
 */
$baselineEndDateInformation.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $baselineEndDateInformation.soyTemplateName = 'ts.commons.UIUtilsTemplate.baselineEndDateInformation';
}


/**
 * @param {!$multiValueSelector.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $multiValueSelector = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $multiValueSelector$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.dropdownText, opt_data.selectValues);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} dropdownText
 * @param {!Array<{value: string, description?: (null|string|undefined),}>} selectValues
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $multiValueSelector$ = function($$areYouAnInternalCaller, $ijData, dropdownText, selectValues) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.multiValueSelector']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.multiValueSelector']({dropdownText: dropdownText, selectValues: selectValues}, $ijData);
  }
  soy.assertParamType(typeof dropdownText === 'string', 'dropdownText', dropdownText, '@param', 'string');
  soy.assertParamType(Array.isArray(selectValues), 'selectValues', selectValues, '@param', '!Array<{value: string, description?: (null|string|undefined),}>');
  let $output = '<div class="ui form"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.multiValueSelector ts/commons/UIUtilsTemplate.soy:690"' : '') + '><div class="field"><select class="ui dropdown selection-dropdown" multiple="">';
  const value39321List = selectValues;
  const value39321ListLen = value39321List.length;
  for (let value39321Index = 0; value39321Index < value39321ListLen; value39321Index++) {
    const value39321Data = value39321List[value39321Index];
    $output += '<option class="item" value="' + soy.$$escapeHtmlAttribute(value39321Data.value) + '">' + soy.$$escapeHtml(value39321Data.value) + '</option>';
  }
  $output += '</select></div><div class="field"><div class="ui primary labeled icon submit button scrolling search dropdown add-dropdown"><i class="plus icon"></i><input class="search" autocomplete="off" tabindex="0" style="padding-left: 4.2em; color: white"><span class="text">' + soy.$$escapeHtml(dropdownText) + '</span><div class="menu" style="left: 0px !important;  width: 40em !important;">';
  const value39397List = selectValues;
  const value39397ListLen = value39397List.length;
  for (let value39397Index = 0; value39397Index < value39397ListLen; value39397Index++) {
    const value39397Data = value39397List[value39397Index];
    $output += '<div class="item" data-value="' + soy.$$escapeHtmlAttribute(value39397Data.value) + '">' + (value39397Data.description != null ? '<div class="ui header">' + soy.$$escapeHtml(value39397Data.value) + '</div><p style="white-space: break-spaces">' + soy.$$escapeHtml(value39397Data.description) + '</p>' : soy.$$escapeHtml(value39397Data.value)) + '</div>';
  }
  $output += '</div></div></div></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $multiValueSelector as multiValueSelector };
export { $multiValueSelector$ as multiValueSelector$ };
/**
 * @typedef {{
 *  dropdownText: string,
 *  selectValues: !Array<{value: string, description?: (null|string|undefined),}>,
 * }}
 */
$multiValueSelector.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $multiValueSelector.soyTemplateName = 'ts.commons.UIUtilsTemplate.multiValueSelector';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $changesOccurredWarning = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.changesOccurredWarning']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.changesOccurredWarning'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui warning message changes-message" style="display: none;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.changesOccurredWarning ts/commons/UIUtilsTemplate.soy:727"' : '') + '><div class="header">Changes have occurred</div><p>Changes have been made. You can continue editing or save now.</p><div class="ui labeled icon buttons"><button class="ui primary button save-button"><i class="save icon"></i>Save</button><button class="ui button undo-button"><i class="undo icon"></i>Undo</button></div></div>');
};
export { $changesOccurredWarning as changesOccurredWarning };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $changesOccurredWarning.soyTemplateName = 'ts.commons.UIUtilsTemplate.changesOccurredWarning';
}


/**
 * @param {!$textWithLineBreaks.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $textWithLineBreaks = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $textWithLineBreaks$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.textLines);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<string>} textLines
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $textWithLineBreaks$ = function($$areYouAnInternalCaller, $ijData, textLines) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.textWithLineBreaks']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.textWithLineBreaks']({textLines: textLines}, $ijData);
  }
  soy.assertParamType(Array.isArray(textLines), 'textLines', textLines, '@param', '!Array<string>');
  let $output = '';
  const textLine39511List = textLines;
  const textLine39511ListLen = textLine39511List.length;
  for (let textLine39511Index = 0; textLine39511Index < textLine39511ListLen; textLine39511Index++) {
    const textLine39511Data = textLine39511List[textLine39511Index];
    $output += (textLine39511Index > 0 ? '<br' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.textWithLineBreaks ts/commons/UIUtilsTemplate.soy:751"' : '') + '>' : '') + soy.$$escapeHtml(textLine39511Data);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $textWithLineBreaks as textWithLineBreaks };
export { $textWithLineBreaks$ as textWithLineBreaks$ };
/**
 * @typedef {{
 *  textLines: !Array<string>,
 * }}
 */
$textWithLineBreaks.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $textWithLineBreaks.soyTemplateName = 'ts.commons.UIUtilsTemplate.textWithLineBreaks';
}


/**
 * @param {!$inputSelect.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $inputSelect = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $inputSelect$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.selectId, opt_data.selectItems, opt_data.selectedItem, opt_data.includeEmptyOption);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} selectId
 * @param {!Array<string>} selectItems
 * @param {null|string|undefined=} selectedItem
 * @param {boolean|null|undefined=} includeEmptyOption
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $inputSelect$ = function($$areYouAnInternalCaller, $ijData, selectId, selectItems, selectedItem, includeEmptyOption) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.inputSelect']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.inputSelect']({selectId: selectId, selectItems: selectItems, selectedItem: selectedItem, includeEmptyOption: includeEmptyOption}, $ijData);
  }
  soy.assertParamType(typeof selectId === 'string', 'selectId', selectId, '@param', 'string');
  soy.assertParamType(Array.isArray(selectItems), 'selectItems', selectItems, '@param', '!Array<string>');
  soy.assertParamType(selectedItem == null || typeof selectedItem === 'string', 'selectedItem', selectedItem, '@param', 'null|string|undefined');
  soy.assertParamType(includeEmptyOption == null || typeof includeEmptyOption === 'boolean', 'includeEmptyOption', includeEmptyOption, '@param', 'boolean|null|undefined');
  let $output = '<select class="ui dropdown" id="' + soy.$$escapeHtmlAttribute(selectId) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.inputSelect ts/commons/UIUtilsTemplate.soy:770"' : '') + '>' + (includeEmptyOption ? '<option value="" selected="selected">- Please select -</option>' : '');
  const item39543List = selectItems;
  const item39543ListLen = item39543List.length;
  for (let item39543Index = 0; item39543Index < item39543ListLen; item39543Index++) {
    const item39543Data = item39543List[item39543Index];
    $output += '<option' + (!includeEmptyOption && soy.$$equals(item39543Data, selectedItem) ? ' selected="selected"' : '') + '>' + soy.$$escapeHtml(item39543Data) + '</option>';
  }
  $output += '</select>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $inputSelect as inputSelect };
export { $inputSelect$ as inputSelect$ };
/**
 * @typedef {{
 *  selectId: string,
 *  selectItems: !Array<string>,
 *  selectedItem?: (null|string|undefined),
 *  includeEmptyOption?: (boolean|null|undefined),
 * }}
 */
$inputSelect.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $inputSelect.soyTemplateName = 'ts.commons.UIUtilsTemplate.inputSelect';
}


/**
 * @param {!$simulinkSignalDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $simulinkSignalDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkSignalDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.source, opt_data.target, opt_data.signals, opt_data.maxDialogContentHeight, opt_data.maxDialogContentWidth);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} source
 * @param {?} target
 * @param {!Array<?>} signals
 * @param {?} maxDialogContentHeight
 * @param {?} maxDialogContentWidth
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $simulinkSignalDialog$ = function($$areYouAnInternalCaller, $ijData, source, target, signals, maxDialogContentHeight, maxDialogContentWidth) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.simulinkSignalDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.simulinkSignalDialog']({source: source, target: target, signals: signals, maxDialogContentHeight: maxDialogContentHeight, maxDialogContentWidth: maxDialogContentWidth}, $ijData);
  }
  soy.assertParamType(Array.isArray(signals), 'signals', signals, '@param', '!Array<?>');
  let $output = '';
  if (signals) {
    $output += '<div class="dependency-dialog-content" style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentHeight)) + 'px; max-width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentWidth)) + 'px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.simulinkSignalDialog ts/commons/UIUtilsTemplate.soy:797"' : '') + '><table class="ui compact striped selectable table"><thead><th><span>' + soy.$$escapeHtml(source) + ' <i class="long arrow alternate right icon"></i> ' + soy.$$escapeHtml(target) + '</span></th><thead>';
    const signal39605List = signals;
    const signal39605ListLen = signal39605List.length;
    for (let signal39605Index = 0; signal39605Index < signal39605ListLen; signal39605Index++) {
      const signal39605Data = signal39605List[signal39605Index];
      $output += '<tr><td width="100%" style="font-weight: 600">' + soy.$$escapeHtml(signal39605Data) + '</td></tr>';
    }
    $output += '</table></div>';
  } else {
    $output += '<div class="dependency-dialog-content-missing"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.simulinkSignalDialog ts/commons/UIUtilsTemplate.soy:817"' : '') + '><p>There is no signal information available for this file.</p><p>Make sure architecture analysis is enabled.</p></div>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $simulinkSignalDialog as simulinkSignalDialog };
export { $simulinkSignalDialog$ as simulinkSignalDialog$ };
/**
 * @typedef {{
 *  source: ?,
 *  target: ?,
 *  signals: !Array<?>,
 *  maxDialogContentHeight: ?,
 *  maxDialogContentWidth: ?,
 * }}
 */
$simulinkSignalDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkSignalDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.simulinkSignalDialog';
}


/**
 * @param {!$page.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $page = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $page$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.caption, opt_data.description, opt_data.buttons, opt_data.fullWidth, opt_data.fullWidthValue, opt_data.captionPrefix, opt_data.captionLabel, opt_data.headerClass, opt_data.headerButtonsClass, opt_data.segmentBorder);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} caption
 * @param {null|string|undefined=} description
 * @param {!Array<?>|null|undefined=} buttons
 * @param {?=} fullWidth
 * @param {?=} fullWidthValue
 * @param {null|number|string|undefined=} captionPrefix
 * @param {null|string|undefined=} captionLabel
 * @param {null|string|undefined=} headerClass
 * @param {null|string|undefined=} headerButtonsClass
 * @param {boolean|null|undefined=} segmentBorder
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $page$ = function($$areYouAnInternalCaller, $ijData, caption, description, buttons, fullWidth, fullWidthValue, captionPrefix, captionLabel, headerClass, headerButtonsClass, segmentBorder) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.page']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.page']({caption: caption, description: description, buttons: buttons, fullWidth: fullWidth, fullWidthValue: fullWidthValue, captionPrefix: captionPrefix, captionLabel: captionLabel, headerClass: headerClass, headerButtonsClass: headerButtonsClass, segmentBorder: segmentBorder}, $ijData);
  }
  soy.assertParamType(typeof caption === 'string', 'caption', caption, '@param', 'string');
  soy.assertParamType(description == null || typeof description === 'string', 'description', description, '@param', 'null|string|undefined');
  soy.assertParamType(buttons == null || Array.isArray(buttons), 'buttons', buttons, '@param', '!Array<?>|null|undefined');
  soy.assertParamType(captionPrefix == null || typeof captionPrefix === 'number' || typeof captionPrefix === 'string', 'captionPrefix', captionPrefix, '@param', 'null|number|string|undefined');
  soy.assertParamType(captionLabel == null || typeof captionLabel === 'string', 'captionLabel', captionLabel, '@param', 'null|string|undefined');
  soy.assertParamType(headerClass == null || typeof headerClass === 'string', 'headerClass', headerClass, '@param', 'null|string|undefined');
  soy.assertParamType(headerButtonsClass == null || typeof headerButtonsClass === 'string', 'headerButtonsClass', headerButtonsClass, '@param', 'null|string|undefined');
  soy.assertParamType(segmentBorder == null || typeof segmentBorder === 'boolean', 'segmentBorder', segmentBorder, '@param', 'boolean|null|undefined');
  let param39649 = '<div class="ui secondary menu page-header"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.page ts/commons/UIUtilsTemplate.soy:857"' : '') + '><h1 class="ui header horizontally fitted item ts-headline ' + soy.$$escapeHtmlAttribute(headerClass != null ? headerClass : '') + '">' + (captionPrefix ? '<span class="ts-headline-prefix">' + soy.$$escapeHtml(captionPrefix) + '</span>' : '') + soy.$$escapeHtml(caption) + '  ' + (captionLabel ? '<span><span class="ui circular label">' + soy.$$escapeHtml(captionLabel) + '</span></span>' : '') + '</h1>';
  if (soy.$$coerceToBoolean(buttons) && buttons.length > 0) {
    param39649 += '<div id="header-buttons" class="ui right secondary menu ' + soy.$$escapeHtmlAttribute(headerButtonsClass != null ? headerButtonsClass : '') + '">';
    const button39707List = buttons;
    const button39707ListLen = button39707List.length;
    for (let button39707Index = 0; button39707Index < button39707ListLen; button39707Index++) {
      const button39707Data = button39707List[button39707Index];
      param39649 += '<div class="fitted item">' + (!button39707Data.href ? '<button id="' + soy.$$escapeHtmlAttribute(button39707Data.id) + '" class="ui button ' + (button39707Data.icon ? 'icon ' : '') + (button39707Data.classParams ? soy.$$escapeHtmlAttribute(button39707Data.classParams) : '') + ' ' + (button39707Data.primary ? 'primary' : '') + '"' + (button39707Data.tooltip ? ' title="' + soy.$$escapeHtmlAttribute(button39707Data.tooltip) + '"' : '') + '><i' + (button39707Data.iconId ? ' id="' + soy.$$escapeHtmlAttribute(button39707Data.iconId) + '"' : '') + ' class="' + soy.$$escapeHtmlAttribute(button39707Data.icon) + '"></i> ' + soy.$$escapeHtml(button39707Data.title) + '</button>' : '<a id="' + soy.$$escapeHtmlAttribute(button39707Data.id) + '" class="ui button ' + (button39707Data.icon ? 'icon ' : '') + (button39707Data.classParams ? soy.$$escapeHtmlAttribute(button39707Data.classParams) : '') + ' ' + (button39707Data.primary ? 'primary' : '') + '"' + (button39707Data.tooltip ? ' title="' + soy.$$escapeHtmlAttribute(button39707Data.tooltip) + '"' : '') + ' href=' + soy.$$escapeHtmlAttributeNospace(soy.$$filterNormalizeUri(button39707Data.href)) + (button39707Data.openInNewTab ? ' target="_blank"' : '') + '><i' + (button39707Data.iconId ? ' id="' + soy.$$escapeHtmlAttribute(button39707Data.iconId) + '"' : '') + ' class="' + soy.$$escapeHtmlAttribute(button39707Data.icon) + '"></i> ' + soy.$$escapeHtml(button39707Data.title) + '</a>') + '</div>';
    }
    param39649 += '</div>';
  }
  param39649 += '</div>' + (description ? '<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.page ts/commons/UIUtilsTemplate.soy:889"' : '') + '>' + soy.$$escapeHtml(description) + '</div>' : '') + '<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.page ts/commons/UIUtilsTemplate.soy:891"' : '') + '>' + $alerts(null, $ijData) + '</div>';
  let $output = '' + $centeredDiv$(soy.$$internalCallMarkerDoNotUse, $ijData, soy.VERY_UNSAFE.$$ordainSanitizedHtmlForInternalBlocks(param39649), fullWidth, fullWidthValue, segmentBorder);
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $page as page };
export { $page$ as page$ };
/**
 * @typedef {{
 *  caption: string,
 *  description?: (null|string|undefined),
 *  buttons?: (!Array<?>|null|undefined),
 *  fullWidth?: (?|undefined),
 *  fullWidthValue?: (?|undefined),
 *  captionPrefix?: (null|number|string|undefined),
 *  captionLabel?: (null|string|undefined),
 *  headerClass?: (null|string|undefined),
 *  headerButtonsClass?: (null|string|undefined),
 *  segmentBorder?: (boolean|null|undefined),
 * }}
 */
$page.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $page.soyTemplateName = 'ts.commons.UIUtilsTemplate.page';
}


/**
 * @param {?$titleDownloadButton.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $titleDownloadButton = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $titleDownloadButton$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.title, opt_data.treemap);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} title
 * @param {boolean|null|undefined=} treemap
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $titleDownloadButton$ = function($$areYouAnInternalCaller, $ijData, title, treemap) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.titleDownloadButton']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.titleDownloadButton']({title: title, treemap: treemap}, $ijData);
  }
  soy.assertParamType(title == null || typeof title === 'string', 'title', title, '@param', 'null|string|undefined');
  soy.assertParamType(treemap == null || typeof treemap === 'boolean', 'treemap', treemap, '@param', 'boolean|null|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="' + soy.$$escapeHtmlAttribute(treemap ? 'treemap-buttons' : '') + ' pull-left"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.titleDownloadButton ts/commons/UIUtilsTemplate.soy:910"' : '') + '><button class="button-download compact ui basic icon button" title="' + (!title ? 'Download' : soy.$$escapeHtmlAttribute(title)) + '"><i class="arrow alternate circle down outline icon"></i></button></div>');
};
export { $titleDownloadButton as titleDownloadButton };
export { $titleDownloadButton$ as titleDownloadButton$ };
/**
 * @typedef {{
 *  title?: (null|string|undefined),
 *  treemap?: (boolean|null|undefined),
 * }}
 */
$titleDownloadButton.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $titleDownloadButton.soyTemplateName = 'ts.commons.UIUtilsTemplate.titleDownloadButton';
}


/**
 * @param {!$issueStatusLabel.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $issueStatusLabel = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $issueStatusLabel$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.issue, opt_data.size, opt_data.useBasicStyle);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} issue
 * @param {?=} size
 * @param {?=} useBasicStyle
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $issueStatusLabel$ = function($$areYouAnInternalCaller, $ijData, issue, size, useBasicStyle) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.issueStatusLabel']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.issueStatusLabel']({issue: issue, size: size, useBasicStyle: useBasicStyle}, $ijData);
  }
  let $output = '';
  let labelColor__soy39875 = '';
  const $tmp = issue.status;
  switch (google.isObject($tmp) ? $tmp.toString() : $tmp) {
    case 'Open':
    case 'open':
    case 'unresolved':
    case 'New':
    case 'Draft':
      labelColor__soy39875 += ' blue';
      break;
    case 'In Review':
    case 'In review':
    case 'Reviewed':
    case 'reviewed':
    case 'Review':
    case 'review':
    case 'Yellow':
    case 'yellow':
    case 'In Progress':
    case 'In progress':
    case 'In development':
    case 'In Development':
      labelColor__soy39875 += ' orange';
      break;
    case 'Closed':
    case 'closed':
    case 'resolved':
    case 'Resolved':
    case 'done':
    case 'Done':
    case 'Merged':
    case 'Approved':
      labelColor__soy39875 += ' green';
      break;
    case 'Rejected':
      labelColor__soy39875 += ' red';
      break;
    default:
      labelColor__soy39875 += ' grey';
  }
  $output += !soy.$$equals(issue.status, '') ? '<span class="ui label ' + (useBasicStyle ? 'basic' : '') + ' ' + (size != null ? soy.$$escapeHtmlAttribute(size) : 'medium') + soy.$$escapeHtmlAttribute(labelColor__soy39875) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.issueStatusLabel ts/commons/UIUtilsTemplate.soy:941"' : '') + '>' + soy.$$escapeHtml(issue.status) + '</span>' : '';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $issueStatusLabel as issueStatusLabel };
export { $issueStatusLabel$ as issueStatusLabel$ };
/**
 * @typedef {{
 *  issue: ?,
 *  size?: (?|undefined),
 *  useBasicStyle?: (?|undefined),
 * }}
 */
$issueStatusLabel.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $issueStatusLabel.soyTemplateName = 'ts.commons.UIUtilsTemplate.issueStatusLabel';
}


/**
 * @param {!$simpleError.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $simpleError = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simpleError$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.message, opt_data.details);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} message
 * @param {null|string|undefined=} details
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $simpleError$ = function($$areYouAnInternalCaller, $ijData, message, details) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.simpleError']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.simpleError']({message: message, details: details}, $ijData);
  }
  soy.assertParamType(typeof message === 'string', 'message', message, '@param', 'string');
  soy.assertParamType(details == null || typeof details === 'string', 'details', details, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui error message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.simpleError ts/commons/UIUtilsTemplate.soy:949"' : '') + '><div class="header"><i class="exclamation circle icon"></i> ' + soy.$$escapeHtml(message) + '</div><p>' + soy.$$escapeHtml(details != null ? details : '') + '</p></div>');
};
export { $simpleError as simpleError };
export { $simpleError$ as simpleError$ };
/**
 * @typedef {{
 *  message: string,
 *  details?: (null|string|undefined),
 * }}
 */
$simpleError.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simpleError.soyTemplateName = 'ts.commons.UIUtilsTemplate.simpleError';
}


/**
 * @param {?$uploadProgress.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $uploadProgress = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $uploadProgress$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.idSuffix);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} idSuffix
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $uploadProgress$ = function($$areYouAnInternalCaller, $ijData, idSuffix) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.uploadProgress']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.uploadProgress']({idSuffix: idSuffix}, $ijData);
  }
  soy.assertParamType(idSuffix == null || typeof idSuffix === 'string', 'idSuffix', idSuffix, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="upload-progress-bar-section"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.uploadProgress ts/commons/UIUtilsTemplate.soy:957"' : '') + '><div style="height: 1em"></div><div' + (idSuffix ? ' id="upload-progress-' + soy.$$escapeHtmlAttribute(idSuffix) + '"' : ' id="upload-progress"') + ' class="ui blue active progress" style="display: none;"><div class="bar"><div class="progress"></div></div><div class="ui label"></div></div></div>');
};
export { $uploadProgress as uploadProgress };
export { $uploadProgress$ as uploadProgress$ };
/**
 * @typedef {{
 *  idSuffix?: (null|string|undefined),
 * }}
 */
$uploadProgress.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $uploadProgress.soyTemplateName = 'ts.commons.UIUtilsTemplate.uploadProgress';
}


/**
 * @param {!$userActivityDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $userActivityDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $userActivityDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.userActivities, opt_data.committerActivities);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} userActivities
 * @param {?} committerActivities
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $userActivityDialog$ = function($$areYouAnInternalCaller, $ijData, userActivities, committerActivities) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.userActivityDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.userActivityDialog']({userActivities: userActivities, committerActivities: committerActivities}, $ijData);
  }
  let $output = '<table class="ui compact striped selectable borderless unstackable framed table body-code-table"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.userActivityDialog ts/commons/UIUtilsTemplate.soy:978"' : '') + '><thead><tr><th>Time span</th><th>Teamscale Users</th><th>Committers</th></tr></thead><tbody>';
  const entry40037List = soy.$$getMapKeys(userActivities);
  const entry40037ListLen = entry40037List.length;
  for (let entry40037Index = 0; entry40037Index < entry40037ListLen; entry40037Index++) {
    const entry40037Data = entry40037List[entry40037Index];
    $output += '<tr><td>' + soy.$$escapeHtml(userActivities[/** @type {?} */ (entry40037Data)][/** @type {?} */ ('since')]) + '</td><td class="right aligned">' + soy.$$escapeHtml(userActivities[/** @type {?} */ (entry40037Data)][/** @type {?} */ ('count')]) + '</td><td class="right aligned">' + soy.$$escapeHtml(committerActivities[/** @type {?} */ (entry40037Data)][/** @type {?} */ ('count')]) + '</td></tr>';
  }
  $output += '</tbody></table>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $userActivityDialog as userActivityDialog };
export { $userActivityDialog$ as userActivityDialog$ };
/**
 * @typedef {{
 *  userActivities: ?,
 *  committerActivities: ?,
 * }}
 */
$userActivityDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $userActivityDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.userActivityDialog';
}
