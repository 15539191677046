import type { History } from 'history';
import type React from 'react';
import { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createPerspectiveHashHistory } from './PerspectiveHashHistory';

/** The possible props for the PerspectiveHashRouter. */
type PerspectiveHashRouterProps = {
	children?: React.ReactNode;
};

/**
 * The global React Router history object which should be used as a low level primitive to efficiently navigate in the
 * UI.
 */
export const HISTORY: History = createPerspectiveHashHistory(window);

/**
 * A Router component that uses a PerspectiveHashHistory to manipulate the browser history. We use this to be able to
 * keep the old URLs working.
 */
export function PerspectiveHashRouter({ children }: PerspectiveHashRouterProps): JSX.Element {
	const [state, setState] = useState({
		action: HISTORY.action,
		location: HISTORY.location
	});

	useLayoutEffect(() => HISTORY.listen(setState), []);

	return (
		<Router navigationType={state.action} location={state.location} navigator={HISTORY}>
			{children}
		</Router>
	);
}
